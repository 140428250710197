import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { getConfLogin, GetConfLoginResponse } from "../api/fetcher";
import { components } from "../api/schema";

const useGetConfLoginData = () => {
  const confLogin = getConfLogin();
  const [loginInfo, setLoginInfo] = useState<components["schemas"]["ConfGetLoginConfResponse"] | undefined>(undefined);

  const { data: versionData } = useQuery<GetConfLoginResponse>({
    queryKey: [confLogin.queryKey],
    queryFn: () => confLogin.queryFn(),
  });

  useEffect(() => {
    setLoginInfo(versionData);
  }, [versionData]);

  return loginInfo;
};

export default useGetConfLoginData;

import { Typography } from "@mui/material";
import clsx from "clsx";
import React from "react";
import InfoIcon from "../Icons/InfoIcon";
import SnapshotIcon from "../Icons/SnapshotIcon";
import { camelCaseToSnakeSpace, capitalizeFirstLetter } from "../utils/formatterUtils";
import useStateWithLocalStorage from "../utils/useStateWithLocalStorage";
import Tooltip from "./Tooltip";

const HAS_HANDLE_CLICK_LINK_GRAFANA = false;
const SNAPSHOT_ICON_SIZE = 16;

const isOperatingSystemMac = window.navigator?.userAgent.includes("Mac");

const LegendWelcomeMessage = () => (
  <>
    <p className="rounded border border-border bg-guideline-lighterPurple p-2">
      Press <b>{isOperatingSystemMac ? <>&#8984;</> : "ctrl"}+click</b> for selecting multiple items
    </p>
  </>
);

interface Props<T> {
  selectedChartComponents: T[];
  setSelectedChartComponents: React.Dispatch<React.SetStateAction<T[]>>;
  componentStyle: Record<string, { color: string }>;
  isDashedFnc?: (key: string) => boolean;
  isSnapshotElementFnc?: (key: string) => boolean;
  ChartComponents: Record<string, T>;
  className?: string;
  fontWeight?: number;
  renderNameFunction?: (key: string) => string;
  hasTooltip?: boolean;
  displayNameFormatter?: (key: string) => string;
  fontSpanClassName?: string;
  displayNameStringTransformer?: (key: string) => string;
  customElementTooltip?: (key: string) => React.ReactNode;
  tooltipMaxWidth?: (key: string) => number;
  hasInfoIcon?: (key: string) => boolean;
  disableTooltip?: boolean;
  onlyShowOptions?: T[];
}

const CustomLegend = <T extends string>({
  selectedChartComponents,
  setSelectedChartComponents,
  componentStyle,
  isDashedFnc,
  isSnapshotElementFnc,
  ChartComponents,
  className,
  fontWeight = 600,
  renderNameFunction,
  hasTooltip,
  displayNameFormatter,
  fontSpanClassName,
  displayNameStringTransformer,
  customElementTooltip,
  tooltipMaxWidth = () => 300,
  hasInfoIcon = () => false,
  disableTooltip,
  onlyShowOptions,
}: Props<T>) => {
  const [hasUserSeenTheLegendWelcomeTooltip, setHasUserSeenTheLegendWelcomeTooltip] = useStateWithLocalStorage<
    string | undefined
  >({
    localStorageKey: "has_user_seen_the_legend_logic_tooltip",
    defaultValue: undefined,
  });

  return (
    <div className={clsx(className, "flex flex-wrap gap-4 justify-center w-full select-none max-h-[60px]")}>
      {Object.keys(ChartComponents).map((key) => {
        if (onlyShowOptions && !onlyShowOptions.includes(ChartComponents[key])) return null;

        const isDashed = isDashedFnc ? isDashedFnc(key) : false;
        const isSnapshotElement = isSnapshotElementFnc ? isSnapshotElementFnc(key) : false;
        const value = ChartComponents[key];
        const isSelected = selectedChartComponents.includes(value);

        let displayName;
        switch (true) {
          case renderNameFunction !== undefined:
            displayName = renderNameFunction && renderNameFunction(key);
            break;
          default:
            displayName = capitalizeFirstLetter(camelCaseToSnakeSpace(ChartComponents[key]).toLowerCase());
        }

        displayName = displayName && displayNameFormatter ? displayNameFormatter(displayName) : displayName;

        let tooltipContent: React.ReactNode | undefined;

        switch (true) {
          case !hasUserSeenTheLegendWelcomeTooltip && Boolean(HAS_HANDLE_CLICK_LINK_GRAFANA):
            tooltipContent = <LegendWelcomeMessage />;
            break;
          case !!customElementTooltip:
            tooltipContent = customElementTooltip && customElementTooltip(key);
            break;
          case hasTooltip:
            tooltipContent = displayName;
            break;
          default:
            tooltipContent = undefined;
        }

        const regularHandleClick = (
          e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.MouseEvent<HTMLSpanElement, MouseEvent>
        ) => {
          if (e.metaKey || e.ctrlKey) {
            if (selectedChartComponents.length === 1 && selectedChartComponents[0] === value) {
              setSelectedChartComponents(Object.values(ChartComponents));
            } else {
              setSelectedChartComponents([value]);
            }
          } else if (isSelected) {
            setSelectedChartComponents(selectedChartComponents.filter((v) => v !== value));
          } else {
            setSelectedChartComponents([...selectedChartComponents, value]);
          }
        };

        const handleClickLinkGrafana = (
          e: React.MouseEvent<HTMLDivElement, MouseEvent> | React.MouseEvent<HTMLSpanElement, MouseEvent>
        ) => {
          if (e.metaKey || e.ctrlKey) {
            if (selectedChartComponents.includes(value)) {
              setSelectedChartComponents((prev) => prev.filter((item) => item !== value));
            } else {
              setSelectedChartComponents((prev) => [...prev, value]);
            }
          } else {
            if (selectedChartComponents.length === 1 && selectedChartComponents[0] === value) {
              setSelectedChartComponents(Object.values(ChartComponents));
            } else {
              setSelectedChartComponents([value]);
            }
          }

          e.stopPropagation();
        };
        return (
          <Typography
            key={key}
            variant="caption"
            fontWeight={fontWeight}
            className={clsx("cursor-pointer flex items-center gap-1 text-black w-fit", {
              "border border-primary-purpleBlue rounded-full px-3 relative": isSnapshotElement,
            })}
            style={{
              opacity: isSelected ? 1 : 0.35,
            }}
            onClick={(e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
              if (!hasUserSeenTheLegendWelcomeTooltip) setHasUserSeenTheLegendWelcomeTooltip("true");
              if (HAS_HANDLE_CLICK_LINK_GRAFANA) {
                handleClickLinkGrafana(e);
              } else {
                regularHandleClick(e);
              }
            }}
          >
            {isSnapshotElement && (
              <SnapshotIcon
                height={SNAPSHOT_ICON_SIZE}
                width={SNAPSHOT_ICON_SIZE}
                className="text-primary-purpleBlue absolute -top-2.5 -left-1 bg-white"
              />
            )}
            <div
              style={{
                background: componentStyle[value]?.color,
              }}
              className="h-2 w-[14px] rounded-lg"
            />
            {isDashed && (
              <div className="flex gap-[2px] justify-center items-center ml-[-15px] pr-[3px]">
                <div className="h-2 w-[3px] bg-white" />
                <div className="h-2 w-[3px] bg-white" />
              </div>
            )}
            <Tooltip
              title={tooltipContent}
              className="truncate"
              disabled={!tooltipContent || disableTooltip}
              maxWidth={tooltipMaxWidth(key)}
            >
              <span className={clsx({ fontSpanClassName, "flex items-center gap-[1px]": hasInfoIcon(key) })}>
                {displayNameStringTransformer ? displayNameStringTransformer(String(displayName)) : displayName}
                {hasInfoIcon(key) && <InfoIcon height={9} width={9} key={displayName} />}
              </span>
            </Tooltip>
          </Typography>
        );
      })}
    </div>
  );
};

export default CustomLegend;

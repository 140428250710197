import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import dayjsPluginUTC from "dayjs/plugin/utc";
import prettyBytes from "pretty-bytes";
import { memo, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ObjectParam, StringParam, useQueryParam } from "use-query-params";
import { getPolicyTuningGraphData, GetPolicyTuningGraphDataResponse } from "../../../api/fetcher";
import { components } from "../../../api/schema";
import DefaultFallback from "../../../components/DefaultFallback";
import { TooltipTrigger } from "../../Analytics/AnalyticsV2/Graphs/hooks/useFreezeTooltip";
import { FrozenTooltipType } from "../../Analytics/AnalyticsV2/Graphs/hooks/utils";
import { SELECTED_CONTAINER_KEY } from "./ContainerFilter";
import UsageChart, { GraphDataType } from "./UsageChart/UsageChart";
import { ChartComponents, getEndDate, POLICY_TUNING_DATES_URL_PARAM, useViewPeriodQueryParams } from "./utils";
import clsx from "clsx";

dayjs.extend(dayjsPluginUTC);

const getParseGraphData = (
  data: GetPolicyTuningGraphDataResponse,
  property: keyof {
    cpuPolicyTuningGraphDataPoints?: components["schemas"]["UtilsPolicyTuningGraphDataPoint"][];
    memoryPolicyTuningGraphDataPoints?: components["schemas"]["UtilsPolicyTuningGraphDataPoint"][];
    gpuPolicyTuningGraphDataPoints?: components["schemas"]["UtilsPolicyTuningGraphDataPoint"][];
    gpuMemoryPolicyTuningGraphDataPoints?: components["schemas"]["UtilsPolicyTuningGraphDataPoint"][];
  },
  decimalPoints = 2
) => {
  let minAllowed: number | undefined;
  let maxAllowed: number | undefined;

  switch (property.includes("cpu")) {
    case true:
      minAllowed = data.cpuCappingConfig?.minAllowed && data.cpuCappingConfig?.minAllowed / 1000;
      maxAllowed = data.cpuCappingConfig?.maxAllowed && data.cpuCappingConfig?.maxAllowed / 1000;
      break;
    case false:
      minAllowed = data.memoryCappingConfig?.minAllowed && data.memoryCappingConfig?.minAllowed / 1024 / 1024;
      maxAllowed = data.memoryCappingConfig?.maxAllowed && data.memoryCappingConfig?.maxAllowed / 1024 / 1024;
      break;
  }

  const rawData = {
    cpuPolicyTuningGraphDataPoints: data.cpuPolicyTuningGraphDataPoints,
    memoryPolicyTuningGraphDataPoints: data.memoryPolicyTuningGraphDataPoints,
    gpuPolicyTuningGraphDataPoints: data.gpuPolicyTuningGraphDataPoints,
    gpuMemoryPolicyTuningGraphDataPoints: data.gpuMemoryPolicyTuningGraphDataPoints,
  };
  return rawData[property]?.map((dataPoint) => ({
    timestamp: dayjs
      .utc(dataPoint?.timestamp?.replace(/(\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2}).*/, "$1").replace("-", "/"))
      .format("YYYY-MM-DD HH:mm:ss Z"),
    podsAvgUsage: dataPoint?.podsAvgUsage?.toFixed(decimalPoints),
    podsMaxUsage: dataPoint?.podsMaxUsage?.toFixed(decimalPoints),
    podsP90Usage: dataPoint?.podsP90Usage?.toFixed(decimalPoints),
    currentRequest: dataPoint?.currentRequest?.toFixed(decimalPoints),
    originRequest: dataPoint?.originRequest?.toFixed(decimalPoints),
    currentLimit: dataPoint?.currentLimit?.toFixed(decimalPoints),
    recommendedRequest: dataPoint?.recommendedRequest?.toFixed(decimalPoints),
    recommendedLimit: dataPoint?.recommendedLimit?.toFixed(decimalPoints),
    minAllowed: minAllowed ? minAllowed.toFixed(decimalPoints) : undefined,
    maxAllowed: maxAllowed ? maxAllowed.toFixed(decimalPoints) : undefined,
  }));
};

const processGpuChartData = (data: GraphDataType | undefined) => {
  return data?.map((dataPoint) => ({
    ...dataPoint,
    podsAvgUsage: `${Number(dataPoint.podsAvgUsage)}`,
  }));
};

interface Props {
  selectedWorkload: components["schemas"]["UtilsWorkload"];
  policyName: string | undefined;
  selectedChartComponents: ChartComponents[];
  setCpuCappingConfig: (config: components["schemas"]["UtilsPolicyTuningCappingConfig"] | undefined) => void;
  setMemoryCappingConfig: (config: components["schemas"]["UtilsPolicyTuningCappingConfig"] | undefined) => void;
  wrapperHeight?: number;
  tooltipTrigger?: TooltipTrigger;
  disabledZoom?: boolean;
  frozenTooltipType?: FrozenTooltipType;
  isGpu?: boolean;
}

const UsageAndMemoryCharts = memo(
  ({
    selectedWorkload,
    policyName,
    selectedChartComponents,
    setCpuCappingConfig,
    setMemoryCappingConfig,
    wrapperHeight,
    tooltipTrigger,
    disabledZoom,
    frozenTooltipType,
    isGpu,
  }: Props) => {
    const policyTuningGraphData = getPolicyTuningGraphData();
    const namespace = selectedWorkload.namespace;
    const name = `${selectedWorkload.type.toLocaleLowerCase()}-${selectedWorkload.workloadName}`;
    const [urlDates] = useQueryParam(POLICY_TUNING_DATES_URL_PARAM, ObjectParam);
    const [selectedViewPeriod] = useViewPeriodQueryParams();
    const [selectedContainer] = useQueryParam(SELECTED_CONTAINER_KEY, StringParam);
    const [someContainersDontHaveCpuLimit, setSomeContainersDontHaveCpuLimit] = useState(false);
    const [someContainersDontHaveMemoryLimit, setSomeContainersDontHaveMemoryLimit] = useState(false);

    const [cpuData, setCpuData] = useState<GraphDataType | undefined>([]);
    const [memoryData, setMemoryData] = useState<GraphDataType | undefined>([]);
    const [gpuData, setGpuData] = useState<GraphDataType | undefined>([]);
    const [gpuMemoryData, setGpuMemoryData] = useState<GraphDataType | undefined>([]);

    const { data, isLoading, isFetching, failureCount } = useQuery<GetPolicyTuningGraphDataResponse, Error>(
      [
        policyTuningGraphData.queryKey,
        name,
        namespace,
        policyName,
        selectedViewPeriod,
        urlDates?.from,
        urlDates?.to,
        selectedContainer,
      ],
      () => {
        return policyTuningGraphData.queryFn({
          name,
          namespace,
          policyName,
          startDate: urlDates?.from
            ? dayjs(Number(urlDates?.from) * 1000)
                .utc()
                .format("YYYY-MM-DDTHH:mm:00")
            : dayjs.utc(getEndDate()).subtract(Number(selectedViewPeriod), "hour").utc().format("YYYY-MM-DDTHH:mm:00"),
          endDate: urlDates?.to
            ? dayjs(Number(urlDates?.to) * 1000)
                .utc()
                .format("YYYY-MM-DDTHH:mm:00")
            : getEndDate(),
          container: selectedContainer,
        });
      },
      {
        retry: 30,
        refetchOnWindowFocus: false,
      }
    );

    useEffect(() => {
      if (data) {
        const rawData = data;
        delete rawData.firstDataPointTimestamp;
        setCpuData(getParseGraphData(rawData, "cpuPolicyTuningGraphDataPoints", 3));
        setMemoryData(getParseGraphData(rawData, "memoryPolicyTuningGraphDataPoints"));
        setGpuData(getParseGraphData(rawData, "gpuPolicyTuningGraphDataPoints"));
        setGpuMemoryData(getParseGraphData(rawData, "gpuMemoryPolicyTuningGraphDataPoints"));
        setCpuCappingConfig(data.cpuCappingConfig);
        setMemoryCappingConfig(data.memoryCappingConfig);
        setSomeContainersDontHaveCpuLimit(!!data.someContainersDontHaveCpuLimit);
        setSomeContainersDontHaveMemoryLimit(!!data.someContainersDontHaveMemoryLimit);
      }
    }, [data]);

    const formattedMemoryData = (isGpu ? gpuMemoryData : memoryData)?.map((dataPoint) => ({
      timestamp: dataPoint.timestamp,
      podsAvgUsage: dataPoint.podsAvgUsage,
      podsMaxUsage: dataPoint.podsMaxUsage,
      podsP90Usage: dataPoint.podsP90Usage,
      currentRequest: dataPoint.currentRequest,
      originRequest: dataPoint.originRequest,
      currentLimit: dataPoint.currentLimit,
      recommendedRequest: dataPoint.recommendedRequest,
      recommendedLimit: dataPoint.recommendedLimit,
      minAllowed: dataPoint.minAllowed,
      maxAllowed: dataPoint.maxAllowed,
    }));

    const showLoadingOnGraph = isLoading || isFetching;

    const hasNoCpuAndMemoryData =
      (!cpuData && !memoryData) || (cpuData && cpuData.length === 0 && memoryData && memoryData.length === 0);

    const limitSelected = selectedChartComponents.includes(ChartComponents.CurrentLimit);

    return (
      <div className={clsx({ "pr-[35px]": !isLoading, "pr-[60px]": isLoading })}>
        {hasNoCpuAndMemoryData && failureCount > 5 && (
          <div className="w-full p-4">
            <Typography variant="body2">Failed to load data</Typography>
          </div>
        )}
        <div className="flex gap-8">
          <div className="w-[50%] z-50">
            <ErrorBoundary
              fallback={<DefaultFallback message="Failed to load CPU Usage Chart. Please check your setup." />}
            >
              <UsageChart
                title={isGpu ? "GPU Compute" : "CPU"}
                selectedChartComponents={
                  isGpu ? [ChartComponents.CurrentRequest, ChartComponents.AverageUsage] : selectedChartComponents
                }
                data={(isGpu ? processGpuChartData(gpuData) : cpuData) as GraphDataType}
                isLoading={showLoadingOnGraph}
                wrapperHeight={wrapperHeight}
                minMaxYAxisDomainValue={0.6}
                disabledZoom={disabledZoom}
                tooltipTrigger={tooltipTrigger}
                frozenTooltipType={frozenTooltipType}
                infoTooltip={
                  limitSelected && someContainersDontHaveCpuLimit
                    ? "The limit is not shown as some containers have it while others don't"
                    : undefined
                }
              />
            </ErrorBoundary>
          </div>
          <div className="w-[50%] z-40">
            <ErrorBoundary
              fallback={<DefaultFallback message="Failed to load Memory Usage Chart. Please check your setup." />}
            >
              <UsageChart
                title={isGpu ? "GPU Memory" : "Memory"}
                selectedChartComponents={
                  isGpu ? [ChartComponents.CurrentRequest, ChartComponents.AverageUsage] : selectedChartComponents
                }
                data={formattedMemoryData as GraphDataType}
                yTickFormatter={(tick: string | number) => {
                  const tickValue = Number(tick) * 1024 * 1024;
                  if (tickValue < 0) return String(tick);
                  return prettyBytes(tickValue || 0.0, {
                    bits: false,
                    binary: true,
                  });
                }}
                isLoading={showLoadingOnGraph}
                wrapperHeight={wrapperHeight}
                minMaxYAxisDomainValue={300}
                disabledZoom={disabledZoom}
                tooltipTrigger={tooltipTrigger}
                frozenTooltipType={frozenTooltipType}
                infoTooltip={
                  limitSelected && someContainersDontHaveMemoryLimit
                    ? "The limit is not shown as some containers have it while others don't"
                    : undefined
                }
              />
            </ErrorBoundary>
          </div>
        </div>
      </div>
    );
  }
);

export default UsageAndMemoryCharts;

import { useQuery } from "@tanstack/react-query";
import { GetEvents, GetEventsResponse } from "../../api/fetcher";
import Events from "../Events";

interface Props {
  namespace: string;
  name: string;
  hasTopSection?: boolean;
}

const WorkloadEvents = ({ namespace, name, hasTopSection }: Props) => {
  const { queryFn, queryKey } = GetEvents();
  const { data, isLoading } = useQuery<GetEventsResponse, Error>({
    queryKey: [queryKey, name],
    queryFn: () => queryFn(namespace, name),
    enabled: !!name,
    refetchInterval: 60 * 1000,
  });

  const events = ((data as GetEventsResponse)?.events || []).map((event) => ({
    time: event.lastTimestamp,
    message: event.message,
  }));

  return <Events events={events} entityName={"workload"} isLoading={isLoading} hasTopSection={hasTopSection} />;
};
export default WorkloadEvents;

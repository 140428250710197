import { Typography } from "@mui/material";
import CreativeInfoIcon from "../../../Icons/CreativeInfoIcon";
import { GetPolicyTuningHpaCurrentDataResponse } from "../../../api/fetcher";
import Tooltip from "../../../components/Tooltip";
import { camelCaseToSnakeSpace } from "../../../utils/formatterUtils";
import { LABEL_BOX_CLASS_WRAPPER, METRIC_BOX_CLASS_NAME } from "./utils";
import HourglassIcon from "../../../Icons/HourglassIcon";
import clsx from "clsx";

const ICON_SIZE = 12;
const MAX_TOOLTIP_WIDTH = 500;

const strategyType = {
  REPLICAS: "replicas",
  USAGE: "usage",
};

const BOX_CLASS_WRAPPER = `${METRIC_BOX_CLASS_NAME} ${LABEL_BOX_CLASS_WRAPPER}`;

interface PercentileBoxProps {
  optimizationStrategy?: string;
  predictAheadMinutes?: number;
  calculatingPrediction?: boolean;
}

const PercentileBox = ({ optimizationStrategy, predictAheadMinutes, calculatingPrediction }: PercentileBoxProps) => {
  let tooltipContent = <>Calculating prediction method</>;
  let strategyText = "";
  switch (true) {
    case optimizationStrategy === strategyType.REPLICAS:
      tooltipContent = calculatingPrediction ? (
        <>
          Historical data is being gathered to analyze workload predictability.
          <br /> This process may take up to 14 days.
        </>
      ) : (
        <>
          This workload is <b>unpredictable</b> based on the usage pattern observed over the past <b>14 days</b>.
        </>
      );
      strategyText = "Unpredictable";
      break;
    case optimizationStrategy === strategyType.USAGE:
      tooltipContent = (
        <>
          This workload is <b>predictable</b> and will scale <b>{predictAheadMinutes ?? "20"} minutes</b> before a usage
          spike.
        </>
      );
      strategyText = "Predictable";
      break;
  }

  const strategyLable = (
    <p>
      <b>Prediction status</b>
      {optimizationStrategy ? `: ${strategyText}` : ""}
    </p>
  );

  return (
    <Tooltip
      title={<Typography variant="caption">{tooltipContent} </Typography>}
      placement="top-end"
      maxWidth={MAX_TOOLTIP_WIDTH}
      disabled={!tooltipContent}
    >
      <div
        className={clsx(BOX_CLASS_WRAPPER, {
          "text-text-disable": !optimizationStrategy,
          "h-[52px] flex-col": calculatingPrediction,
        })}
        style={{ marginTop: 0 }}
      >
        <div className="flex items-center justify-center gap-1">
          {" "}
          {strategyLable}
          <div className="w-[12px]">
            <CreativeInfoIcon width={ICON_SIZE} height={ICON_SIZE} />
          </div>
        </div>
        {calculatingPrediction && (
          <div className="flex items-center justify-center gap-1 italic">
            <HourglassIcon width={ICON_SIZE - 1} height={ICON_SIZE} />
            calculating predictability...
          </div>
        )}
      </div>
    </Tooltip>
  );
};

const OptimizationGapBox = ({ reason }: { reason: string }) => (
  <Tooltip
    title={
      <Typography variant="caption">
        <b>Optimization gap</b>
        <p className="italic">HPA optimization could not be achieved</p>
        <p>
          Workload has optimization gap due to: <span className="italic">{camelCaseToSnakeSpace(reason)}</span>.
        </p>
      </Typography>
    }
    placement="left"
    maxWidth={MAX_TOOLTIP_WIDTH}
  >
    <div className={BOX_CLASS_WRAPPER}>
      Optimization gap
      <div className="w-[12px]">
        <CreativeInfoIcon width={ICON_SIZE} height={ICON_SIZE} />
      </div>
    </div>
  </Tooltip>
);

interface Props {
  data: GetPolicyTuningHpaCurrentDataResponse | undefined;
}

const PredictableBadges = ({ data }: Props) => {
  if (!data) return null;

  return (
    <>
      {
        <PercentileBox
          optimizationStrategy={data?.optimizationStrategy}
          predictAheadMinutes={data?.predictionMetadata?.predictAheadMinutes}
          calculatingPrediction={data?.predictionMetadata?.calculatingPrediction}
        />
      }
      {data?.optimizationGap && <OptimizationGapBox reason={data?.optimizationGap ?? "tempMessage"} />}
    </>
  );
};

export default PredictableBadges;

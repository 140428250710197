import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { ObjectParam, useQueryParam } from "use-query-params";
import { GetWorkloadEventsGraph, GetWorkloadEventsGraphResponse } from "../../../../api/fetcher";
import WorkloadEvents from "../../../../components/WorkloadStatusByNamespace/WorkloadEvents";
import EventsIcon from "../../../../Icons/EventsIcon";
import { MOCKED_HISTORY_EVENTS_CHART_DATA } from "../../../../utils/developmentFeatureFlags";
import SelectViewPeriod from "../SelectViewPeriod";
import { POLICY_TUNING_DATES_URL_PARAM, useViewPeriodQueryParams } from "../utils";
import { EventChartPoint, exampleData } from "./utils";
import WorkloadHistoryEventChart from "./WorkloadHistoryEventChart";

const NOW = dayjs().unix() * 1000;
const { queryKey, queryFn } = GetWorkloadEventsGraph();

interface Props {
  kind: string;
  name: string;
  namespace: string;
  recommendationName: string;
}

const WorkloadHistoryEventsContainer = ({ kind, name, namespace, recommendationName }: Props) => {
  const [chartData, setChartData] = useState<EventChartPoint[]>([]);
  const [urlDates] = useQueryParam(POLICY_TUNING_DATES_URL_PARAM, ObjectParam);
  const [selectedViewPeriod] = useViewPeriodQueryParams();

  const { data } = useQuery<GetWorkloadEventsGraphResponse, Error>({
    queryKey: [queryKey, kind, name, namespace, urlDates?.from, urlDates?.to, selectedViewPeriod],
    queryFn: () =>
      queryFn({
        kind,
        name,
        namespace,
        from:
          dayjs(
            urlDates?.from ? Number(urlDates.from) * 1000 : dayjs(NOW).subtract(Number(selectedViewPeriod ?? 1), "hour")
          ).unix() * 1000,
        to: dayjs(urlDates?.to ? Number(urlDates.to) * 1000 : NOW).unix() * 1000,
      }),
  });

  useEffect(() => {
    if (data) {
      const transformedData: EventChartPoint[] =
        data.eventGraphPoints?.map((point) => {
          return {
            timestamp: Number(point.timestamp),
            podOptimizedCount: point.podOptimizedCount,
            podEvictionCount: point.podEvictionCount,
            optimizationBlockedCount: point.optimizationBlockedCount,
            data: { ...point.data, podEvictionCount: point.podEvictionCount },
          };
        }) ?? [];

      setChartData(transformedData);
    }
  }, [data]);

  return (
    <div className={"flex flex-col gap-5"}>
      <div className="flex border border-border rounded p-4 items-center gap-10">
        <div className="flex gap-10 w-full">
          <EventsIcon width={40} height={40} />
          <Typography variant="body2">
            <b>History Events</b>
            <p>Explore your workload events</p>
          </Typography>
        </div>
        <div className="h-16 w-[1px] bg-border" />
        <SelectViewPeriod />
      </div>
      <WorkloadHistoryEventChart data={MOCKED_HISTORY_EVENTS_CHART_DATA ? exampleData : chartData} />
      <WorkloadEvents namespace={namespace} name={recommendationName} hasTopSection={false} />
    </div>
  );
};

export default WorkloadHistoryEventsContainer;

import { Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import { components } from "../../../api/schema";
import RightArrowIcon from "../../../Icons/RightArrowIcon";
import { ScaleOpsProduct } from "../../../utils/typesUtils";
import Tooltip from "../../Tooltip";
import { WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME } from "../overviewUtils";
import {
  isUnallocatedRow,
  WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY,
  WorkloadOverviewTooltipMessage,
} from "./GridColumnsUtils";

export default function MinReplicasDiffColumn({
  params,
  scaleOpsProduct,
  showRightSizeValue,
}: {
  params: GridRenderCellParams<string, components["schemas"]["UtilsWorkload"], string>;
} & {
  scaleOpsProduct: ScaleOpsProduct | undefined;
  showRightSizeValue?: boolean;
}) {
  const isHPAProduct = scaleOpsProduct === ScaleOpsProduct.HPA && !showRightSizeValue;
  const hpaOptimizationEnabled = scaleOpsProduct === ScaleOpsProduct.HPA;

  if (isUnallocatedRow(params.row)) return null;
  const hpaOptimizationReady = hpaOptimizationEnabled && params.row.hasHpa && params.row.isReadyRecommendation == true;
  return (
    <Typography variant="body2" className="fullCellTooltipContent">
      <Tooltip
        title={WorkloadOverviewTooltipMessage(params.row.namespace, params.row.workloadName, params.row.type)}
        maxWidth={500}
        className={WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME}
        enterDelay={WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY}
        disabled={hpaOptimizationReady}
      >
        <div className="flex justify-end items-center gap-[.625rem]">
          <span>{params.row.minReplicas ?? params.row.replicas}</span>
          {isHPAProduct && (
            <>
              <RightArrowIcon width={10} height={10} />
              <span className="text-guideline-darkGreen">
                {params.row.recommendedMinReplicas ?? params.row.minReplicas ?? params.row.replicas}
              </span>
            </>
          )}
        </div>
      </Tooltip>
    </Typography>
  );
}

import { IconProps } from "./utils/utils";

const HourglassIcon = (props: IconProps) => {
  const { height = 20, width = 20, className } = props;
  return (
    <svg height={height} width={width} className={className} fill="currentColor" viewBox="0 0 502 502" {...props}>
      <g>
        <g>
          <g>
            <path
              d="M191.153,318.771c-4.544-3.139-10.773-1.999-13.911,2.547c-14.111,20.439-23.336,45.848-26.678,73.481
				c-0.663,5.482,3.244,10.465,8.727,11.128c0.408,0.049,0.812,0.073,1.213,0.073c4.987,0,9.302-3.725,9.915-8.799
				c2.954-24.427,11.005-46.738,23.281-64.519C196.838,328.137,195.697,321.909,191.153,318.771z"
            ></path>
            <path
              d="M225.527,281.826c-8.654,3.205-16.97,7.928-24.718,14.037c-4.337,3.419-5.08,9.707-1.661,14.044
				c1.973,2.503,4.901,3.809,7.859,3.809c2.167,0,4.351-0.702,6.186-2.148c6.103-4.813,12.59-8.509,19.281-10.987
				c5.178-1.918,7.822-7.672,5.904-12.851C236.459,282.551,230.705,279.907,225.527,281.826z"
            ></path>
            <path
              d="M231.521,204.547c-22.139-13.708-39.346-33.096-47.209-53.192c-2.013-5.142-7.812-7.68-12.957-5.668
				c-5.143,2.013-7.681,7.814-5.668,12.957c9.408,24.04,29.565,46.969,55.305,62.908c1.639,1.016,3.458,1.5,5.255,1.5
				c3.347,0,6.619-1.681,8.511-4.738C237.666,213.618,236.216,207.455,231.521,204.547z"
            ></path>
            <path
              d="M394.754,421.894h-6.878c-1.235-46.804-14.953-90.669-38.962-124.165c-15.546-21.689-34.284-37.524-54.825-46.729
				c20.542-9.205,39.279-25.039,54.825-46.729c24.008-33.496,37.727-77.361,38.962-124.165h6.878
				c22.085,0,40.054-17.968,40.054-40.053C434.807,17.968,416.84,0,394.754,0H107.246C85.16,0,67.192,17.968,67.192,40.053
				c0,22.085,17.968,40.053,40.054,40.053h6.878c1.235,46.804,14.953,90.669,38.962,124.165
				c15.546,21.689,34.284,37.524,54.825,46.729c-20.542,9.205-39.279,25.039-54.825,46.729
				c-24.008,33.496-37.727,77.361-38.962,124.165h-6.878c-22.085,0-40.054,17.968-40.054,40.053S85.16,502,107.246,502h287.509
				c22.085,0,40.054-17.968,40.054-40.053S416.84,421.894,394.754,421.894z M107.246,60.106c-11.058,0-20.054-8.995-20.054-20.053
				C87.192,28.996,96.187,20,107.246,20h287.509c11.058,0,20.054,8.996,20.054,20.053s-8.996,20.053-20.054,20.053H107.246z
				 M134.13,80.106h233.739c-2.553,88.969-53.993,160.227-116.87,160.227S136.682,169.075,134.13,80.106z M250.999,261.667
				c62.877,0,114.317,71.258,116.869,160.227H134.13C136.682,332.925,188.122,261.667,250.999,261.667z M394.754,482H107.246
				c-11.058,0-20.054-8.996-20.054-20.053s8.996-20.053,20.054-20.053h287.509c11.058,0,20.054,8.996,20.054,20.053
				S405.811,482,394.754,482z"
            ></path>
          </g>
        </g>
      </g>
    </svg>
  );
};

export default HourglassIcon;

import { useFormikContext } from "formik";
import FormSingleSelect from "../../../../components/formComponents/FormSingleSelect";
import FormSwitch from "../../../../components/formComponents/FormSwitch";
import FormTitleAndDescription from "../../../../components/formComponents/FormTitleAndDescription";
import useIsReadyOnlyFrontEnd from "../../../../utils/useIsReadyOnlyFrontEnd";
import { HPA_SECTION_CLASS_NAME } from "../../../Policies/EditFormSections/utils";

const options: [string, string][] = [
  ["10m", "10m"],
  ["15m", "15m"],
  ["20m", "20m"],
  ["30m", "30m"],
  ["1h", "1h"],
];

interface Props {
  isCustomizedPolicy?: boolean;
}

const PredictableWorkloadsPrediction = ({ isCustomizedPolicy }: Props) => {
  const isReadOnlyFrontEnd = useIsReadyOnlyFrontEnd();
  const { values } = useFormikContext<{
    predictionEnabled: boolean;
    predictionLookAheadDuration: string;
  }>();

  return (
    <div className={HPA_SECTION_CLASS_NAME}>
      <div className="flex flex-col gap-4">
        <FormTitleAndDescription
          title="Prediction"
          description={<>Predict the workload behavior based on historical data and react accordingly.</>}
        />
      </div>
      <div className="flex flex-col gap-4">
        <FormSwitch
          label="Enable prediction"
          name="predictionEnabled"
          disableChange={!isCustomizedPolicy || isReadOnlyFrontEnd}
        />
        <FormSingleSelect
          label="Look ahead duration"
          className="w-[100px]"
          name="predictionLookAheadDuration"
          options={options}
          defaultValue="15m"
          areOptionsDisabled={!isCustomizedPolicy || isReadOnlyFrontEnd}
          disabled={!values?.predictionEnabled}
        />
      </div>
    </div>
  );
};

export default PredictableWorkloadsPrediction;

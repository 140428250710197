import { Typography } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import clsx from "clsx";
import { components } from "../../../api/schema";
import RightArrowIcon from "../../../Icons/RightArrowIcon";
import * as formatterUtils from "../../../utils/formatterUtils";
import ScaleOpsSpan from "../../ScaleOpsSpan";
import Tooltip from "../../Tooltip";
import ValueChip from "../../ValueChip";
import { WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME } from "../overviewUtils";
import {
  getPercentageOfOptimizedReplicas,
  isUnallocatedRow,
  WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY,
  WorkloadOverviewTooltipMessage,
} from "./GridColumnsUtils";

const cpuFormatter = formatterUtils.CpuFormatter();

export default function CpuRequestColumn({
  params,
}: {
  params: GridRenderCellParams<string, components["schemas"]["UtilsWorkload"], string>;
}) {
  const percentageOfOptimizedReplicas = getPercentageOfOptimizedReplicas(params);
  let cpuRequestsDisplayValue = cpuFormatter.format(params.row.cpuRequests / 1000);
  cpuRequestsDisplayValue = Number.isNaN(cpuRequestsDisplayValue) ? "0" : cpuRequestsDisplayValue;
  let cpuRecommendedDisplayValue = cpuFormatter.format(params.row.cpuRecommended / 1000);
  cpuRecommendedDisplayValue = Number.isNaN(cpuRecommendedDisplayValue) ? "0" : cpuRecommendedDisplayValue;

  let cpuDiff =
    (params.row.ownerCpuRequests / 1000 - params.row.cpuRecommended / 1000) * (params.row?.optimizedReplicas ?? 0);

  cpuDiff = Number(cpuDiff);

  if (Number.isNaN(cpuDiff)) cpuDiff = 0;

  const showValueChip = !params.row.isScaleOpsWorkload && params.row.auto && !Number.isNaN(cpuDiff) && cpuDiff >= 0.01;

  const valueChipTooltipContent = showValueChip ? (
    <>
      <ScaleOpsSpan className="mt-[4px]" /> has saved{" "}
      <b className="text-guideline-darkGreen">{formatterUtils.customNumberFormatter(Math.abs(cpuDiff))}</b> CPUs for
      this workload.
    </>
  ) : undefined;

  const valueChip = (
    <ValueChip
      value={cpuDiff}
      renderValue={(value) => formatterUtils.customNumberFormatter(Math.abs(value))}
      hide={!showValueChip}
      tooltipContent={valueChipTooltipContent}
    />
  );
  if (isUnallocatedRow(params.row)) return null;

  return (
    <Typography variant="body2" fontWeight={500} className="fullCellTooltipContent">
      <Tooltip
        title={WorkloadOverviewTooltipMessage(params.row.namespace, params.row.workloadName, params.row.type)}
        maxWidth={500}
        className={WORKLOAD_OVERVIEW_TOOLTIP_CLASS_NAME}
        enterDelay={WORKLOAD_OVERVIEW_TOOLTIP_ENTER_DELAY}
        disabled={showValueChip}
      >
        <div className="flex justify-end items-center gap-[.625rem]">
          <span
            className={clsx({
              "text-main-red":
                !(percentageOfOptimizedReplicas > 50 && params.row.auto) &&
                params.row.cpuRecommended > params.row.cpuRequests * 1.1,
            })}
          >
            {percentageOfOptimizedReplicas > 50 && params.row.auto
              ? cpuRecommendedDisplayValue
              : cpuRequestsDisplayValue}
          </span>
          {params.row.isReadyRecommendation == true && (
            <>
              <RightArrowIcon width={10} height={10} />
              <span className="text-guideline-darkGreen">{cpuRecommendedDisplayValue}</span>
            </>
          )}
        </div>
        <div className="absolute bottom-1.5">{valueChip}</div>
      </Tooltip>
    </Typography>
  );
}

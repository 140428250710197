import { Checkbox } from "@mui/material";
import { GridRenderCellParams } from "@mui/x-data-grid";
import clsx from "clsx";
import { components } from "../../../api/schema";
import { SCALEOPS_COLORS } from "../../../colors";
import SelectedCheckBoxIcon from "../../../Icons/SelectedCheckBoxIcon";
import Tooltip from "../../Tooltip";
import { CantEditScaleopsWorkloadsTooltip } from "../utils";

export interface CheckboxColumnProps {
  selectedRows: components["schemas"]["UtilsWorkload"][] | undefined;
  setSelectedRows: (rows: components["schemas"]["UtilsWorkload"][] | undefined) => void;
}

export function CheckboxColumnHeader({
  selectedRows,
  setSelectedRows,
  allRows,
}: CheckboxColumnProps & {
  allRows: components["schemas"]["UtilsWorkload"][];
}) {
  const allSelectableRows = allRows.filter((row) => !row.isScaleOpsWorkload);
  const selectedAvailableRows = selectedRows?.filter((row) => !row.isScaleOpsWorkload);
  return (
    <Checkbox
      checked={selectedAvailableRows?.length === allSelectableRows?.length && selectedAvailableRows?.length > 0}
      onChange={(e) => {
        if (e.target.checked) {
          setSelectedRows(allSelectableRows);
        } else {
          setSelectedRows([]);
        }
      }}
      sx={{
        "& .MuiSvgIcon-root": {
          color: SCALEOPS_COLORS.primary.purpleBlue,
        },
      }}
      checkedIcon={<SelectedCheckBoxIcon checked />}
      icon={<SelectedCheckBoxIcon />}
    />
  );
}

export default function CheckboxColumn({
  params,
  selectedRows,
  setSelectedRows,
}: {
  params: GridRenderCellParams<
    components["schemas"]["DashIssues"],
    components["schemas"]["UtilsWorkload"],
    components["schemas"]["DashIssues"]
  >;
} & CheckboxColumnProps) {
  const checked = !!selectedRows?.find((row) => row.id === params.row.id);

  return (
    <Tooltip
      title={CantEditScaleopsWorkloadsTooltip}
      disabled={!params.row.isScaleOpsWorkload}
      className={"ml-[-14px]"}
    >
      <div
        className={clsx("w-full flex justify-center", {
          "opacity-30 hover:opacity-100": !checked,
        })}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Checkbox
          checked={checked}
          onChange={(e) => {
            if (e.target.checked) {
              setSelectedRows([...(selectedRows ?? []), params.row]);
            } else {
              setSelectedRows(selectedRows?.filter((row) => row.id !== params.row.id));
            }
          }}
          sx={{
            "& .MuiSvgIcon-root": {
              color: SCALEOPS_COLORS.primary.purpleBlue,
            },
          }}
          checkedIcon={<SelectedCheckBoxIcon checked />}
          icon={<SelectedCheckBoxIcon />}
          disabled={params.row.isScaleOpsWorkload}
        />
      </div>{" "}
    </Tooltip>
  );
}

import { Typography } from "@mui/material";
import clsx from "clsx";
import { FieldArray, useFormikContext } from "formik";
import { components } from "../../../api/schema";
import FormCheckBox from "../../../components/formComponents/FormCheckBox";
import AddIcon from "../../../Icons/AddIcon";
import DeleteIcon from "../../../Icons/DeleteIcon";
import HistoryIcon from "../../../Icons/HistoryIcon";
import HistoryCollection from "./HistoryCollection";
import Period from "./Period";
import PolicyField from "./PolicyField";
import { DEFAULT_PERIOD } from "./useGetDefalutSchedulePolicy";

interface Props {
  index: number;
  remove: <T>(index: number) => T | undefined;
  numberOfRules: number;
  isCustomizedPolicy: boolean;
}

const Rule = ({ index, remove, numberOfRules, isCustomizedPolicy }: Props) => {
  const { values } = useFormikContext<{
    rules?: components["schemas"]["V1alpha1SchedulePolicyRule"][];
  }>();

  const isSleepModeEnabled = values?.rules?.[index]?.sleepMode;
  const isDeleteEnabled = isCustomizedPolicy && numberOfRules > 1;

  return (
    <div className="relative p-4 w-full flex flex-col bg-guideline-lightGray rounded-lg border border-border items-start gap-5">
      <div className="relative w-full flex items-start gap-2.5">
        <div>
          <PolicyField
            name={`rules[${index}].policyName`}
            label="Policy name"
            isCustomizedPolicy={isCustomizedPolicy}
            className="bg-white"
            disabled={isSleepModeEnabled}
          />
          <FormCheckBox
            name={`rules[${index}].sleepMode`}
            label={<Typography variant="caption">Sleep</Typography>}
            disableChange={!isCustomizedPolicy}
            disabled={!isCustomizedPolicy}
            className="mt-3"
            info={
              <>
                Scale to <b>zero replicas</b>.
              </>
            }
          />
        </div>
        <HistoryCollection
          name={`rules[${index}].useRelativeHistoryData`}
          isCustomizedPolicy={isCustomizedPolicy}
          disabled={isSleepModeEnabled}
        />
      </div>
      <div className="w-full flex flex-col gap-2.5">
        <div className="w-full flex justify-between items-end">
          <Typography variant="body2" fontWeight={700}>
            Periods
          </Typography>
          <span className="text-[12px] flex items-center gap-1 mt-1">
            <HistoryIcon width={14} height={14} /> UTC time zone
          </span>
        </div>
        <FieldArray name={`rules[${index}].periods`}>
          {({ push: pushPeriod, remove: removePeriod }) => (
            <div className="flex flex-col gap-3.5">
              {values.rules?.[index].periods?.map((_, periodIndex, allPeriods) => (
                <Period
                  index={periodIndex}
                  ruleIndex={index}
                  periodIndex={periodIndex}
                  namePrefix={`rules[${index}].periods[${periodIndex}]`}
                  remove={removePeriod}
                  numberOfPeriods={allPeriods.length}
                  isCustomizedPolicy={isCustomizedPolicy}
                />
              ))}
              <button type="button" className="flex justify-end cursor-auto">
                <AddIcon
                  width={14}
                  height={14}
                  onClick={() =>
                    pushPeriod({
                      ...DEFAULT_PERIOD,
                    })
                  }
                  className="cursor-pointer hover:opacity-80"
                />
              </button>
            </div>
          )}
        </FieldArray>
      </div>
      <DeleteIcon
        type="button"
        onClick={() => {
          if (isDeleteEnabled) remove(index);
        }}
        width={14}
        height={14}
        className={clsx("absolute top-1.5 right-1.5", {
          "cursor-not-allowed opacity-20": !isDeleteEnabled,
          "hover:opacity-80 cursor-pointer": isDeleteEnabled,
        })}
      />
    </div>
  );
};

export default Rule;

// Get date from days ago (epoch time)
import dayjs, { ConfigType, Dayjs, OptionType } from "dayjs";

const ISO_DATE_TIME_REGEX = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d+)?([+-]\d{2}:\d{2}|Z)$/; // ISO 8601 - yyyy-MM-ddTHH:mm:SS+HH:mm or yyyy-MM-ddTHH:mm:ssZ
const UTC_DATE_TIME_REGEX = /^\d{4}-\d{2}-\d{2} \d{2}:\d{2}:\d{2} [+-]\d{4} UTC$/; // UTC date time format - yyyy-MM-dd HH:mm:ss ±HHmm UTC
const SIMPLE_DATE_TIME_REGEX = /^(\d{2})\/([A-Za-z]{3})\/(\d{4}) (\d{2}):(\d{2})$/; // dd/MMM/yyyy HH:mm
const SIMPLE_DATE_TIME_REGEX_WITH_GMT = /^(\d{2})\/([A-Za-z]{3})\/(\d{4}) (\d{2}):(\d{2}) GMT$/; // dd/MMM/yyyy HH:mm GMT
const monthMap: { [key: string]: string } = {
  Jan: "01",
  Feb: "02",
  Mar: "03",
  Apr: "04",
  May: "05",
  Jun: "06",
  Jul: "07",
  Aug: "08",
  Sep: "09",
  Oct: "10",
  Nov: "11",
  Dec: "12",
};

export const get24HoursAgoEpoch = (fromEpoch: number) => {
  return fromEpoch - 24 * 60 * 60 * 1000;
};

export const getEpochStartDate = (daysAgo: number, currentDate: number) => {
  const date = new Date(currentDate);
  date.setDate(date.getDate() - daysAgo);
  date.setHours(0, 0, 0, 0);
  return date.getTime();
};

export function adjustedDayjs(date?: ConfigType): Dayjs;

export function adjustedDayjs(date?: ConfigType, format?: OptionType, strict?: boolean): Dayjs;

export function adjustedDayjs(date?: ConfigType, format?: OptionType, locale?: string, strict?: boolean): Dayjs;

export function adjustedDayjs(
  date?: ConfigType,
  format?: OptionType,
  strictOrLocale?: boolean | string,
  strict?: boolean
): Dayjs {
  if (typeof date === "string") {
    date = convertStringDateToISO(date);
  }
  if (typeof strictOrLocale === "boolean") {
    return dayjs(date, format, strictOrLocale);
  }
  return dayjs(date, format, strictOrLocale, strict);
}

const convertStringDateToISO = (dateString: string): string => {
  if (!ISO_DATE_TIME_REGEX.test(dateString)) {
    // this is done to handle safari and firefox date parsing issue
    switch (true) {
      case UTC_DATE_TIME_REGEX.test(dateString):
        return convertUTCDateToISO(dateString);
      case SIMPLE_DATE_TIME_REGEX.test(dateString):
        return convertSimpleDateToISO(dateString);
      case SIMPLE_DATE_TIME_REGEX_WITH_GMT.test(dateString):
        return convertSimpleDateToISO(dateString, true);
    }
  }
  return dateString;
};

const convertUTCDateToISO = (dateString: string): string => {
  const [datePart, timePart, offsetPart] = dateString.split(" ");
  const dateTimeWithoutOffset = `${datePart}T${timePart}`; // Convert to ISO-like format
  const offsetFormatted = `${offsetPart.slice(0, 3)}:${offsetPart.slice(3)}`; // "+0000" -> "+00:00"
  return `${dateTimeWithoutOffset}${offsetFormatted}`; // Combine the date-time and the formatted offset
};

const convertSimpleDateToISO = (dateString: string, withGMT = false): string => {
  if (withGMT) {
    const [, day, month, year, hour, minute] = dateString.match(SIMPLE_DATE_TIME_REGEX_WITH_GMT) as string[];
    return `${year}-${monthMap[month]}-${day}T${hour}:${minute}:00+00:00`;
  }
  const [, day, month, year, hour, minute] = dateString.match(SIMPLE_DATE_TIME_REGEX) as string[];
  return `${year}-${monthMap[month]}-${day}T${hour}:${minute}:00`;
};

export const reduceMinutesFromHour = (hour: string, minutes: number) => {
  const [hourStr, minuteStr] = hour.split(":");
  let hourInt = parseInt(hourStr);
  let minuteInt = parseInt(minuteStr);
  minuteInt -= minutes;
  if (minuteInt < 0) {
    minuteInt += 60;
    hourInt -= 1;
  }
  if (hourInt < 0) {
    hourInt += 24;
  }
  return `${hourInt.toString().padStart(2, "0")}:${minuteInt.toString().padStart(2, "0")}`;
};

import FormSingleSelectWithDescription from "../../../components/formComponents/FormSingleSelectWithDescription";
import HistoryCollectionIcon from "../../../Icons/HistoryCollectionIcon";

export enum HistoryCollectionType {
  SelectedPeriods = "selectedPeriods",
  Always = "always",
}

const options = [
  {
    value: HistoryCollectionType.SelectedPeriods,
    label: "Current selected time periods",
    description: (
      <>
        Apply a recommendation based on <b>the selected time periods</b> and selected history window.
      </>
    ),
  },
  {
    value: HistoryCollectionType.Always,
    label: "Always",
    description: (
      <>
        Apply a recommendation based on <b>all data points</b> of the selected history window.
      </>
    ),
  },
];

interface Props {
  name: string;
  disabled?: boolean;
  isCustomizedPolicy?: boolean;
}

const HistoryCollection = ({ name, disabled, isCustomizedPolicy }: Props) => {
  return (
    <FormSingleSelectWithDescription
      label={
        <div className="flex gap-1 items-center">
          <HistoryCollectionIcon width={14} height={14} className="text-primary-purpleBlue" />
          <span>History collection</span>
        </div>
      }
      name={name}
      options={options}
      disabled={disabled}
      className="bg-white w-full"
      wrapperClassName="w-full"
      areOptionsDisabled={!isCustomizedPolicy}
    />
  );
};

export default HistoryCollection;

import React, { useCallback, useEffect, useState } from "react";

import {
  Alert,
  AlertProps,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Drawer,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { Box } from "@mui/system";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { components } from "../../../api/schema";
import InternalButton from "../../../components/Button";
import "../../../theme";

import { toast } from "react-toastify";
import CustomIcon from "../../../Icons/CustomIcon";
import LockIcon from "../../../Icons/LockIcon";
import { ScaleOpsClient } from "../../../api/api";
import { getPolicies, GetPoliciesResponse } from "../../../api/fetcher";
import useGetApi from "../../../api/useGetApi";
import Tooltip from "../../../components/Tooltip";
import { HAS_NEW_SCHEDULE_POLICY_LAYOUT } from "../../../utils/developmentFeatureFlags";
import * as policyUtils from "../../../utils/policyUtils";
import EditPolicyDrawer, { TOP_CHIP_CLASS_NAME } from "../EditPolicyDrawer";
import PoliciesContainer from "../PoliciesContainer";
import SchedulePolicyDrawer from "../SchedulePolicy/SchedulePolicyDrawer";
import { PolicyType } from "../utils";
import TabContent from "./TabContent";
import { defaultPolicyParams } from "./defaultPolicy";

interface Cause {
  reason: string;
  message: string;
  field: string;
}

interface Details {
  group: string;
  kind: string;
  name: string;
  causes?: Cause[];
}

interface Error {
  code: number;
  status: string;
  message: string;
  reason: string;
  details: Details;
}

interface Data {
  error: Error;
}

interface PolicyException {
  status: number;
  statusText: string;
  data: Data;
}

function fetchMetricsConf(
  api: ScaleOpsClient,
  setAvailableMetricsList: React.Dispatch<
    React.SetStateAction<components["schemas"]["V1alpha1MetricConf"][] | undefined>
  >
) {
  api
    .getFetcher()
    .path("/api/v1/metricsConf/")
    .method("get")
    .create()({})
    .then((response) => {
      if (response != undefined) {
        if (response.ok) {
          if (response?.data?.metricsConf != undefined) {
            setAvailableMetricsList(response.data.metricsConf);
          }
        }
      }
    })
    .catch((error) => console.error(error));
}

const policiesFetcher = getPolicies();

export default function PolicyTable() {
  const [newPolicyName, setNewPolicyName] = useState("");
  const queryClient = useQueryClient();
  const [selectedPolicy, setSelectedPolicy] = useState<components["schemas"]["V1alpha1Policy"]>();
  const [selectedSchedulePolicy, setSelectedSchedulePolicy] = useState<
    components["schemas"]["V1alpha1Policy"] | undefined
  >();
  const [isEditPolicyDrawerOpen, setIsEditPolicyDrawerOpen] = useState(false);
  const [fetchedDefaultProductionPolicy, setFetchedDefaultProductionPolicy] =
    useState<components["schemas"]["V1alpha1Policy"]>();

  const { data } = useQuery<GetPoliciesResponse, Error>({
    queryKey: [policiesFetcher.queryKey],
    queryFn: policiesFetcher.queryFn,
  });

  const defaultPolicy = (
    fetchedDefaultProductionPolicy: components["schemas"]["V1alpha1Policy"] | undefined,
    policies: components["schemas"]["V1alpha1Policy"][]
  ) => {
    const newPoliciesNamesNumbers = policies
      .map((policy) => policy.metadata?.name)
      .filter((name) => name?.startsWith("new-policy-"))
      .map((name) => parseInt(name?.split("-")[2] || "0"))
      .filter((num) => !isNaN(num));

    const largestValue = Math.max(...newPoliciesNamesNumbers, 0);

    let policy: components["schemas"]["V1alpha1Policy"] = fetchedDefaultProductionPolicy || defaultPolicyParams;
    policy = JSON.parse(JSON.stringify(policy)) as components["schemas"]["V1alpha1Policy"];
    policy.metadata = {};
    policy.metadata.name = `new-policy-${largestValue + 1}`;
    policy.spec = { ...policy.spec, type: "Optimize" };
    policy.metadata.labels = { "scaleops.sh/builtin-policy": "false" };
    policy.metadata.annotations = {};

    return policy;
  };

  const api = useGetApi();
  const [policies, setPolicies] = useState<components["schemas"]["V1alpha1Policy"][]>([]);
  const [priorityClasses, setPriorityClasses] = useState<components["schemas"]["V1PriorityClass"][]>([]);
  const [selectedPolicyToUpdate, setSelectedPolicyToUpdate] = useState<
    components["schemas"]["V1alpha1Policy"] | undefined
  >();
  const [isNew, setIsNew] = useState(false);
  const [isNewPolicy, setIsNewPolicy] = useState(false);
  const [type, setType] = useState<string | undefined>(undefined);

  // Form Errors
  const [nameError, setNameError] = useState<React.ReactNode | undefined>();
  const [cpuHeadroomError, setCPUHeadroomError] = useState<React.ReactNode | undefined>();
  const [memoryHeadroomError, setMemoryHeadroomError] = useState<React.ReactNode | undefined>();
  const [cpuHistoryWindowError, setCPUHistoryWindowError] = useState<React.ReactNode | undefined>();
  const [memoryHistoryWindowError, setMemoryHistoryWindowError] = useState<React.ReactNode | undefined>();
  const [cpuPercentileError, setCPUPercentileError] = useState<React.ReactNode | undefined>();
  const [memoryPercentileError, setMemoryPercentileError] = useState<React.ReactNode | undefined>();
  const [maxUnavailablePodsPercentageError, setMaxUnavailablePodsPercentageError] = useState<
    React.ReactNode | undefined
  >();
  const [evictionScheduleScaleUpError, setEvictionScheduleScaleUpError] = useState<React.ReactNode | undefined>();
  const [EvictionScheduleScaleDownError, setEvictionScheduleScaleDownError] = useState<React.ReactNode | undefined>();
  const [PolicyOverrideScheduleFromTimeError, setPolicyOverrideScheduleFromTimeError] = useState<
    React.ReactNode | undefined
  >();
  const [PolicyOverrideScheduleToTimeError, setPolicyOverrideScheduleToTimeError] = useState<
    React.ReactNode | undefined
  >();
  const [PolicyOverrideScheduleNameError, setPolicyOverrideScheduleNameError] = useState<React.ReactNode | undefined>();
  const [PolicyPriorityError, setPolicyPriorityError] = useState<React.ReactNode | undefined>();
  const [PolicyPriorityNameError, setPolicyPriorityNameError] = useState<React.ReactNode | undefined>();
  const [MinChangeScaleUpCpuError, setMinChangeScaleUpCpuError] = useState<React.ReactNode | undefined>();
  const [MinChangeScaleDownCpuError, setMinChangeScaleDownCpuError] = useState<React.ReactNode | undefined>();
  const [MinChangeScaleUpMemoryError, setMinChangeScaleUpMemoryError] = useState<React.ReactNode | undefined>();
  const [MinChangeScaleDownMemoryError, setMinChangeScaleDownMemoryError] = useState<React.ReactNode | undefined>();

  const errorsDict: { [errorType: string]: React.ReactNode } = {
    nameError: nameError,
    cpuHeadroomError: cpuHeadroomError,
    memoryHeadroomError: memoryHeadroomError,
    cpuHistoryWindowError: cpuHistoryWindowError,
    memoryHistoryWindowError: memoryHistoryWindowError,
    cpuPercentileError: cpuPercentileError,
    memoryPercentileError: memoryPercentileError,
    maxUnavailablePodsPercentageError: maxUnavailablePodsPercentageError,
    evictionScheduleScaleUpError: evictionScheduleScaleUpError,
    EvictionScheduleScaleDownError: EvictionScheduleScaleDownError,
    PolicyOverrideScheduleFromTimeError: PolicyOverrideScheduleFromTimeError,
    PolicyOverrideScheduleToTimeError: PolicyOverrideScheduleToTimeError,
    PolicyOverrideScheduleNameError: PolicyOverrideScheduleNameError,
    PolicyPriorityError: PolicyPriorityError,
    PolicyPriorityNameError: PolicyPriorityNameError,
    minChangeScaleUpCpuError: MinChangeScaleUpCpuError,
    minChangeScaleDownCpuError: MinChangeScaleDownCpuError,
    minChangeScaleUpMemoryError: MinChangeScaleUpMemoryError,
    minChangeScaleDownMemoryError: MinChangeScaleDownMemoryError,
  };

  const handleClick = (p: components["schemas"]["V1alpha1Policy"]) => {
    if (HAS_NEW_SCHEDULE_POLICY_LAYOUT && policyUtils.policyType(p) === "Schedule") {
      setSelectedSchedulePolicy(p);
    } else {
      const profileDeepCopy = { ...p };
      setSelectedPolicyToUpdate(profileDeepCopy);
      setIsNew(false);
      setType(policyUtils.policyType(profileDeepCopy));
    }
  };

  const handleError = (exception: PolicyException) => {
    if (exception.data.error.details.causes != undefined) {
      exception.data.error.details.causes.forEach((cause) => {
        switch (cause.field) {
          case "metadata.name": {
            setNameError(cause.message);
            break;
          }
          case 'spec.rightSizePolicy.requestsConfigs["cpu"].headroomPercentage': {
            setCPUHeadroomError(cause.message);
            break;
          }
          case 'spec.rightSizePolicy.requestsConfigs["memory"].headroomPercentage': {
            setMemoryHeadroomError(cause.message);
            break;
          }
          case 'spec.rightSizePolicy.window["cpu"]': {
            setCPUHistoryWindowError(cause.message);
            break;
          }
          case 'spec.rightSizePolicy.window["memory"]': {
            setMemoryHistoryWindowError(cause.message);
            break;
          }
          case 'spec.rightSizePolicy.requestsConfigs["cpu"].percentilePercentage': {
            setCPUPercentileError(cause.message);
            break;
          }
          case 'spec.rightSizePolicy.requestsConfigs["memory"].percentilePercentage': {
            setMemoryPercentileError(cause.message);
            break;
          }
          case "spec.updatePolicy.maxUnavailablePodsPercentage": {
            setMaxUnavailablePodsPercentageError(cause.message);
            break;
          }
          case "spec.updatePolicy.evictionSchedule.scaleUp": {
            setEvictionScheduleScaleUpError(cause.message);
            break;
          }
          case "spec.updatePolicy.evictionSchedule.scaleDown": {
            setEvictionScheduleScaleDownError(cause.message);
            break;
          }
          default: {
            console.log(exception);
            break;
          }
        }
      });
    } else {
      if (exception.data.error.message.indexOf("already exists") > -1) {
        setNameError("Policy with name " + exception.data.error.details.name + " already exists");
      }
    }
  };

  useEffect(() => {
    api
      .getFetcher()
      .path("/api/v1/policies")
      .method("get")
      .create()({})
      .then((response) => {
        if (response.ok) {
          if (response.data.policies !== undefined) {
            const defaultProductionPolicyData = response.data.policies.find(
              (policy) => policy.metadata?.name === "production"
            );
            if (defaultProductionPolicyData) {
              setFetchedDefaultProductionPolicy(defaultProductionPolicyData);
            }
            setPolicies(response.data.policies);
          }
        }
      })
      .catch((err) => console.log(err));
  }, [api]);

  useEffect(() => {
    api
      .getFetcher()
      .path("/api/v1/priorityClasses/")
      .method("get")
      .create()({})
      .then((response) => {
        if (response.ok) {
          if (response.data.priorityClasses != undefined) {
            setPriorityClasses(response.data.priorityClasses);
          }
        }
      })
      .catch((err) => console.log(err));
  }, [api]);
  const [availableMetricsList, setAvailableMetricsList] = useState<components["schemas"]["V1alpha1MetricConf"][]>();
  useEffect(() => {
    fetchMetricsConf(api, setAvailableMetricsList);
  }, [api]);

  const handleNew = () => {
    const defaultPolicyData = defaultPolicy(fetchedDefaultProductionPolicy, policies);
    setSelectedPolicyToUpdate(defaultPolicyData);
    setSelectedPolicy(defaultPolicyData);
    setIsNew(true);
    setIsNewPolicy(true);
  };

  const [snackbar, setSnackbar] = useState<Pick<AlertProps, "children" | "severity"> | null>(null);
  const handleCloseSnackbar = () => setSnackbar(null);

  const handleProcessRowUpdateError = useCallback((message: string) => {
    setSnackbar({ children: message, severity: "error" });
  }, []);

  const handleProcessRowUpdateSuccess = useCallback((message: string) => {
    setSnackbar({ children: message, severity: "success" });
  }, []);

  function saveNewPolicy(
    policy: components["schemas"]["V1alpha1Policy"],
    successCb?: (policy?: components["schemas"]["V1alpha1Policy"]) => void
  ) {
    api
      .getFetcher()
      .path("/api/v1/policy/")
      .method("post")
      .create()({
        policy: policy,
      })
      .then((response) => {
        if (!(response.ok && response.data != undefined && response.data?.policy != undefined)) {
          handleProcessRowUpdateError("Failed to create a policy");
          return;
        }
        const policy: components["schemas"]["V1alpha1Policy"] = response.data?.policy;
        setPolicies(policies.concat(policy));
        if (successCb != undefined) {
          successCb(policy);
        }
        handleProcessRowUpdateSuccess("Policy successfully created");
      })
      .catch((e: PolicyException) => {
        toast.error("Failed to create a policy");
        handleError(e);
      })
      .finally(() => {
        queryClient.invalidateQueries([policiesFetcher.queryKey]);
        setSelectedPolicyToUpdate(undefined);
        setType(undefined);
      });
  }

  const save = () => {
    setNameError(undefined);
    setCPUHeadroomError(undefined);
    setMemoryHeadroomError(undefined);
    setCPUHistoryWindowError(undefined);
    setMemoryHistoryWindowError(undefined);
    setCPUPercentileError(undefined);
    setMemoryPercentileError(undefined);
    setMaxUnavailablePodsPercentageError(undefined);
    setEvictionScheduleScaleUpError(undefined);
    setEvictionScheduleScaleDownError(undefined);
    setPolicyOverrideScheduleNameError(undefined);
    setPolicyOverrideScheduleToTimeError(undefined);
    setPolicyOverrideScheduleFromTimeError(undefined);
    setPolicyPriorityError(undefined);
    setPolicyPriorityNameError(undefined);
    setMinChangeScaleUpCpuError(undefined);
    setMinChangeScaleDownCpuError(undefined);
    setMinChangeScaleUpMemoryError(undefined);
    setMinChangeScaleDownMemoryError(undefined);

    if (selectedPolicyToUpdate != undefined) {
      if (isNew) {
        saveNewPolicy(selectedPolicyToUpdate);
      } else {
        if (selectedPolicyToUpdate?.metadata?.name != undefined) {
          api
            .getFetcher()
            .path("/api/v1/policy/{name}")
            .method("put")
            .create()({
              policy: selectedPolicyToUpdate,
              name: selectedPolicyToUpdate.metadata?.name,
            })
            .then(() => {
              setSelectedPolicyToUpdate(undefined);

              api
                .getFetcher()
                .path("/api/v1/policies")
                .method("get")
                .create()({})
                .then((response) => {
                  if (response.ok) {
                    if (response.data.policies != undefined) {
                      handleProcessRowUpdateSuccess("Policy successfully updated");
                      setPolicies(response.data.policies);
                    }
                  } else {
                    handleProcessRowUpdateError("Failed to update a policy");
                  }
                })
                .catch((err) => console.log(err));
            })
            .catch(handleError);
        }
      }
    }
  };

  const handleDuplicateClick = (policy: components["schemas"]["V1alpha1Policy"]) => {
    setSelectedPolicyToDuplicate(policy);
    setNewPolicyName(policyUtils.policyName(policy) + "-copy");
  };

  const cancel = () => {
    setSelectedPolicyToUpdate(undefined);
    setType(undefined);
  };

  const handleNewSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const newNameItem: HTMLInputElement | null = event.currentTarget.elements.namedItem(
      "newName"
    ) as HTMLInputElement | null;
    if (newNameItem != null) {
      const newPolicyName: string = newNameItem.value;
      const oldPolicy = policies.find((p) => policyUtils.policyName(p) == selectedCheckbox);
      if (oldPolicy) {
        const newPolicy = {
          ...oldPolicy,
          metadata: {
            ...oldPolicy.metadata,
            resourceVersion: undefined,
            name: newPolicyName,
            labels: { "scaleops.sh/builtin-policy": "false" },
            annotations: {},
          },
        };

        saveNewPolicy(newPolicy, () => {
          setSelectedPolicyToDuplicate(undefined);
          setNameError(undefined);
        });
      }
    }
  };

  const [selectedCheckbox, setSelectedCheckbox] = useState<string | undefined>(undefined);
  const [selectedPolicyToDuplicate, setSelectedPolicyToDuplicate] = useState<
    components["schemas"]["V1alpha1Policy"] | undefined
  >(undefined);

  const isCustomizedPolicy =
    selectedPolicyToUpdate?.metadata?.name &&
    !Object.values(PolicyType).join(" ").includes(selectedPolicyToUpdate?.metadata?.name);

  return (
    <div>
      <PoliciesContainer
        openCreateNewPolicyDialog={handleNew}
        openEditPolicyDialog={handleClick}
        handleDuplicateClick={handleDuplicateClick}
        setSelectedCheckbox={setSelectedCheckbox}
        setPolicies={setPolicies}
      />
      <Paper sx={{ width: "100%", overflow: "hidden" }}>
        <Dialog
          open={selectedPolicyToDuplicate != undefined}
          onClose={() => {
            setSelectedPolicyToDuplicate(undefined);
            setNameError(undefined);
          }}
        >
          <Box component="form" onSubmit={handleNewSubmit}>
            <DialogTitle className="bg-text-lightBlack text-white min-h-[56px] flex items-center">
              <Typography variant="body2">Duplicate Policy</Typography>
            </DialogTitle>
            <DialogContent className="h-[200px] w-[400px] flex items-center">
              <TextField
                value={newPolicyName}
                onClick={(e) => {
                  e.stopPropagation();
                }}
                onChange={(e) => {
                  setNewPolicyName(
                    e.target.value
                      .toLowerCase()
                      .replace(" ", "-")
                      .replace(/ /g, "-")
                      .replace(/^[^a-zA-Z]+/, "")
                      .replace(/[^a-zA-Z0-9-]/g, "")
                  );
                }}
                required
                fullWidth
                error={nameError != undefined}
                helperText="Please enter a valid name."
                name="newName"
                id="newName"
                margin="dense"
                placeholder="main"
                key="newName"
                label="New Name"
                variant="standard"
              />
            </DialogContent>
            <DialogActions>
              <InternalButton label="Duplicate" type="submit" />
            </DialogActions>
          </Box>
        </Dialog>
        <Drawer
          onClose={() => {
            setSelectedPolicyToUpdate(undefined);
            setType(undefined);
          }}
          aria-labelledby="form-dialog-title"
          open={selectedPolicyToUpdate !== undefined}
          style={{ overflowY: "auto", display: "flex", flexDirection: "column", zIndex: 1300 }}
          anchor="right"
        >
          <DialogTitle className="bg-text-lightBlack text-white min-h-[56px] flex gap-2 items-center">
            {!policyUtils.isBuiltInPolicy(selectedPolicyToUpdate) && <LockIcon width={24} height={24} />}
            <Typography variant="body2">
              {type != undefined || selectedPolicyToUpdate == undefined
                ? selectedPolicyToUpdate?.metadata?.name || ""
                : "Create New Policy"}
            </Typography>
            {policyUtils.isBuiltInPolicy(selectedPolicyToUpdate) && (
              <Typography variant="caption" className={TOP_CHIP_CLASS_NAME}>
                <CustomIcon width={14} height={14} />
                build in policy
              </Typography>
            )}
          </DialogTitle>
          <DialogContent style={{ height: "850px" }}>
            <TabContent
              selectedPolicy={selectedPolicy}
              setSelectedPolicy={setSelectedPolicy}
              setIsEditPolicyDrawerOpen={setIsEditPolicyDrawerOpen}
              setSelectedSchedulePolicy={setSelectedSchedulePolicy}
              errorsDict={errorsDict}
              selectedPolicyToUpdate={selectedPolicyToUpdate || {}}
              setSelectedPolicyToUpdate={setSelectedPolicyToUpdate}
              isNewPolicy={isNew}
              policies={policies}
              priorityClasses={priorityClasses}
              type={type}
              setType={setType}
              availableMetricsList={availableMetricsList || []}
            />
          </DialogContent>
          <DialogActions>
            <div className="flex justify-end gap-3 p-1">
              <InternalButton onClick={cancel} label="Cancel" />
              {isCustomizedPolicy && <InternalButton onClick={save} label="Save" disabled={type == undefined} />}
              {!isCustomizedPolicy && (
                <div className="flex items-center">
                  <Tooltip
                    title={
                      <>
                        <b>Default policies can't be edited</b>.<br />
                        You can only edit customized policies
                      </>
                    }
                  >
                    <LockIcon width={24} height={24} />
                  </Tooltip>
                </div>
              )}
            </div>
          </DialogActions>
        </Drawer>
      </Paper>
      {!!snackbar && (
        <Snackbar
          open
          anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
          onClose={handleCloseSnackbar}
          autoHideDuration={6000}
        >
          <Alert {...snackbar} onClose={handleCloseSnackbar} />
        </Snackbar>
      )}
      <EditPolicyDrawer
        rowPolicyData={selectedPolicy}
        isExtended={!!data?.usingExtendedWindows}
        isEditPolicyDrawerOpen={isEditPolicyDrawerOpen}
        setIsEditPolicyDrawerOpen={setIsEditPolicyDrawerOpen}
        isNewPolicy={isNewPolicy}
        onSavingNewPolicy={() => {
          setIsNew(false);
          setIsNewPolicy(false);
          setSelectedPolicy(undefined);
          setSelectedPolicyToUpdate(undefined);
        }}
      />
      <SchedulePolicyDrawer
        selectedSchedulePolicy={selectedSchedulePolicy}
        setSelectedSchedulePolicy={setSelectedSchedulePolicy}
        setIsNew={setIsNew}
        isNew={isNew}
      />
    </div>
  );
}

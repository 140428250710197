import { Typography } from "@mui/material";
import RightArrowWithFill from "../../Icons/RightArrowWithFill";
import WarningIcon from "../../Icons/WarningIcon";
import { components } from "../../api/schema";
import { LIGHT_PURPLE_BLUE, MAIN_YELLOW } from "../../colors";
import ExploreWorkloadsMessage from "./NodeOverview/ExploreWorkloadsMessage";
import {
  BlockingOwner,
  NodeReasons,
  PodReasons,
  noPlaceToMovePodsReasonsMessages,
  nodeReasonsMessages,
  podReasonsMessages,
} from "./Utils";

const ArrowLine = ({ content }: { content: React.ReactNode }) => (
  <div className="flex gap-2.5 items-start">
    <div className="h-[20px] flex items-center">
      <RightArrowWithFill width={14} height={14} fill={LIGHT_PURPLE_BLUE} outline="none" />
    </div>
    <Typography variant="caption">{content}</Typography>
  </div>
);

const DotLine = ({ content }: { content: React.ReactNode }) => (
  <div className="flex gap-2.5 items-start">
    <div className="h-[20px] flex items-center">
      <span className="bg-primary-lightPurpleBlue rounded-full w-1.5 h-1.5" />
    </div>
    <Typography variant="caption">{content}</Typography>
  </div>
);

interface NotScalingDownTooltipContentProps {
  nodeGroup: string;
  limitScaleDownMessage: string;
  nodeGroups: components["schemas"]["UtilsNodeGroupInfo"][] | undefined;
  title?: string;
  icon?: React.ReactNode;
  limitScaleDownReason?: string;
  blockingOwner?: string;
  blockingName?: string;
  blockingMessage?: string;

  limitScaleDownAction?: string;
  nodeReasonDetails?: {
    [key: string]: components["schemas"]["Node_details_checkerUnscheduableDetails"] | undefined;
  };
}

export const NotScalingDownTooltipContent = ({
  nodeGroup,
  limitScaleDownMessage,
  limitScaleDownReason,
  limitScaleDownAction,
  blockingOwner,
  blockingName,
  blockingMessage,
  nodeReasonDetails,
  nodeGroups,
  title = "Scale down blockers",
  icon = <WarningIcon fill={MAIN_YELLOW} />,
}: NotScalingDownTooltipContentProps) => {
  blockingOwner = blockingOwner as keyof typeof blockingOwner;
  let reason: React.ReactNode;

  const message = nodeReasonsMessages[limitScaleDownReason as unknown as NodeReasons];

  switch (blockingOwner) {
    case BlockingOwner.Node:
      reason =
        limitScaleDownReason && Object.values(NodeReasons).includes(limitScaleDownReason as unknown as NodeReasons) ? (
          message.renderFunction ? (
            message.renderFunction(nodeGroup, nodeGroups, limitScaleDownMessage)
          ) : (
            <>
              <p>{message.jsx}</p>
            </>
          )
        ) : undefined;
      break;
    case BlockingOwner.Pod:
      reason =
        limitScaleDownReason && Object.values(PodReasons).includes(limitScaleDownReason as unknown as PodReasons)
          ? podReasonsMessages[limitScaleDownReason as unknown as PodReasons](blockingName, limitScaleDownMessage)
          : undefined;
      break;
    default:
      break;
  }

  if (!reason) {
    reason = blockingMessage;
  } else {
    reason = (
      <>
        <p>{reason}</p>
        {!!limitScaleDownAction && (
          <>
            <br />
            <p>
              <b>{limitScaleDownAction}</b>
            </p>
          </>
        )}
      </>
    );
  }

  const hasNoPlaceToMovePods = limitScaleDownReason === PodReasons.NoPlaceToMovePods;

  let noPlaceToMovePodsReasonsMessagesArray: (string | undefined)[] | undefined;

  if (hasNoPlaceToMovePods) {
    noPlaceToMovePodsReasonsMessagesArray =
      nodeReasonDetails &&
      Object.keys(nodeReasonDetails).map((key) => {
        if (nodeReasonDetails) return nodeReasonDetails[key]?.message;
      });
  }

  return (
    <div className="flex flex-col gap-5">
      <div className="bg-primary-lightPurpleBlue  h-[30px] p-[5px] flex gap-[10px] justify-left items-center w-full rounded-sm">
        {icon}
        <Typography variant="caption" fontWeight={700}>
          {title}
        </Typography>
      </div>
      <Typography variant="caption">
        <p className="underline">Reasons:</p>
        <div>
          <ArrowLine content={reason} />
        </div>
        {hasNoPlaceToMovePods && (
          <div className="pl-6">
            {noPlaceToMovePodsReasonsMessagesArray?.map((reason, key) => {
              const messageObject = Object.values(noPlaceToMovePodsReasonsMessages).find((message) =>
                reason?.includes(message.string)
              );

              if (!messageObject && reason) return <DotLine key={key} content={reason} />;
              if (!reason || !messageObject) return null;

              const splitContent = reason.split(messageObject.string);

              const content = (
                <>
                  {splitContent[0]}
                  {messageObject.jsx}
                  {splitContent[1]}
                </>
              );

              return <DotLine key={key} content={content} />;
            })}
          </div>
        )}
      </Typography>
      <ExploreWorkloadsMessage />
    </div>
  );
};

export default NotScalingDownTooltipContent;

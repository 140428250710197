import { useFormikContext } from "formik";
import FormInput from "../../../../components/formComponents/FormInput";
import FormSingleSelect from "../../../../components/formComponents/FormSingleSelect";
import FormTitleAndDescription from "../../../../components/formComponents/FormTitleAndDescription";
import useIsReadyOnlyFrontEnd from "../../../../utils/useIsReadyOnlyFrontEnd";
import { HPA_SECTION_CLASS_NAME } from "../../../Policies/EditFormSections/utils";
import { MinReplicasStrategy } from "./utils";

interface Props {
  isCustomizedPolicy?: boolean;
}

const OPTIONS = Object.entries(MinReplicasStrategy).map((entity) => [entity[1], entity[1]]);

const GeneralMinimumReplicas = ({ isCustomizedPolicy }: Props) => {
  const isReadOnlyFrontEnd = useIsReadyOnlyFrontEnd();

  const { values } = useFormikContext<{
    minReplicasStrategy: MinReplicasStrategy;
  }>();

  return (
    <div className={HPA_SECTION_CLASS_NAME}>
      <FormTitleAndDescription
        title="Min replicas"
        description="Override the minimum replicas recommendation for the attached workloads."
      />
      <div className="flex flex-col gap-4">
        <FormSingleSelect
          label="Min replicas strategy"
          className="w-[400px]"
          name="minReplicasStrategy"
          options={OPTIONS as [string, string][]}
          areOptionsDisabled={!isCustomizedPolicy}
          defaultValue={MinReplicasStrategy.SetHistoryBasedForAllWorkloads}
        />
        {values.minReplicasStrategy === MinReplicasStrategy.SetMinReplicasForAllWorkloads && (
          <FormInput
            label="Min replicas"
            name="generalMinReplicas"
            type="number"
            disableChange={!isCustomizedPolicy || isReadOnlyFrontEnd}
          />
        )}
        {values.minReplicasStrategy !== MinReplicasStrategy.SetMinReplicasForAllWorkloads && (
          <FormInput
            label="Min replicas boundary"
            name="generalMinimumReplicasResourceBoundary"
            type="number"
            disableChange={!isCustomizedPolicy || isReadOnlyFrontEnd}
          />
        )}
      </div>
    </div>
  );
};

export default GeneralMinimumReplicas;

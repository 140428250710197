import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { getPolicies, GetPoliciesResponse } from "../../../api/fetcher";
import { components } from "../../../api/schema";

const DEFAULT_SCHEDULE_POLICY_NAME = "production";

export const DEFAULT_PERIOD = {
  weeklyConfig: {
    beginTime: "00:00",
    endTime: "00:00",
    days: [0, 1, 2, 3, 4, 5, 6],
  },
};

const policies = getPolicies();

const useGetDefaultSchedulePolicy = () => {
  const [defaultSchedulePolicy, setDefaultSchedulePolicy] = useState<
    components["schemas"]["V1alpha1Policy"] | undefined
  >(undefined);

  const { data } = useQuery<GetPoliciesResponse, Error>({
    queryKey: [policies.queryKey],
    queryFn: policies.queryFn,
  });

  useEffect(() => {
    if (data) {
      let defaultPolicy = data.policies?.find((policy) => policy.metadata?.name === "production");

      defaultPolicy = {
        ...defaultPolicy,
        metadata: {
          namespace: defaultPolicy?.metadata?.namespace,
        },
        spec: {
          ...defaultPolicy?.spec,
          type: "Schedule",
          policySchedule: {
            ...defaultPolicy?.spec?.policySchedule,
            schedulePolicyConfig: {
              defaultPolicy: DEFAULT_SCHEDULE_POLICY_NAME,
              ...defaultPolicy?.spec?.policySchedule?.schedulePolicyConfig,
              rules: [
                {
                  periods: [DEFAULT_PERIOD],
                  policyName: DEFAULT_SCHEDULE_POLICY_NAME,
                  sleepMode: false,
                  useRelativeHistoryData: true,
                },
              ],
            },
          },
        },
      };

      setDefaultSchedulePolicy(defaultPolicy);
    }
  }, [data]);

  return defaultSchedulePolicy;
};
export default useGetDefaultSchedulePolicy;

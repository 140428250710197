import AceEditor from "react-ace";
import "ace-builds/src-noconflict/mode-yaml";
import "ace-builds/src-noconflict/theme-github_dark";
import "ace-builds/src-noconflict/theme-nord_dark";

interface Props {
  yaml: string;
}

const YamlViewer = ({ yaml }: Props) => {
  return (
    <AceEditor
      mode={"yaml"}
      theme={"nord_dark"}
      width={"100%"}
      className="rounded border border-gray-300"
      fontSize={14}
      showPrintMargin={false}
      showGutter={true}
      highlightActiveLine={false}
      wrapEnabled={true}
      readOnly={true}
      setOptions={{
        autoScrollEditorIntoView: true,
        useWorker: true,
        showLineNumbers: false,
        tabSize: 2,
        maxLines: 34,
        behavioursEnabled: false,
        indentedSoftWrap: true,
      }}
      value={yaml ? `\n${yaml}` : yaml}
    />
  );
};

export default YamlViewer;

import { useFormikContext } from "formik";
import FormSingleSelect, { RenderValueType } from "../../../../components/formComponents/FormSingleSelect";
import FormSwitch from "../../../../components/formComponents/FormSwitch";
import FormTitleAndDescription from "../../../../components/formComponents/FormTitleAndDescription";
import useIsReadyOnlyFrontEnd from "../../../../utils/useIsReadyOnlyFrontEnd";
import { HPA_SECTION_CLASS_NAME } from "../../../Policies/EditFormSections/utils";
import { minReplicaPercentileOptions, MinReplicasStrategy, minReplicaWindowOptions } from "./utils";

interface Props {
  isCustomizedPolicy?: boolean;
}

const PredictableMinReplicas = ({ isCustomizedPolicy }: Props) => {
  const isReadOnlyFrontEnd = useIsReadyOnlyFrontEnd();

  const { values } = useFormikContext<{
    minReplicasStrategy: MinReplicasStrategy;
  }>();

  const isDisabled =
    values.minReplicasStrategy === MinReplicasStrategy.KeepMinReplicasForAllWorkloads ||
    values.minReplicasStrategy === MinReplicasStrategy.SetMinReplicasForAllWorkloads ||
    values.minReplicasStrategy === MinReplicasStrategy.SetHistoryBasedForStaticWorkloads;

  return (
    <div className={HPA_SECTION_CLASS_NAME}>
      <FormTitleAndDescription
        title="Min replicas"
        description="Define the history window and percentile for the suggested min replicas optimization."
      />
      <div className="flex flex-col gap-4">
        <FormSwitch
          label=" Enable min replicas optimization"
          name="predictionMinReplicasEnabled"
          disableChange={!isCustomizedPolicy || isReadOnlyFrontEnd}
          disabled={isDisabled}
        />
        <div className="flex gap-8">
          <FormSingleSelect
            label="History window"
            name="predictionMinReplicasHistoryWindow"
            options={minReplicaWindowOptions}
            areOptionsDisabled={!isCustomizedPolicy || isReadOnlyFrontEnd}
            disabled={isDisabled}
            defaultValue={"336h"}
          />
          <FormSingleSelect
            label="Percentile"
            name="predictionMinReplicasPercentile"
            options={minReplicaPercentileOptions}
            renderValueType={RenderValueType.Percentage}
            areOptionsDisabled={!isCustomizedPolicy || isReadOnlyFrontEnd}
            disabled={isDisabled}
            defaultValue="80"
          />
        </div>
      </div>
    </div>
  );
};

export default PredictableMinReplicas;

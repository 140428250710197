import { Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import React, { memo, useEffect, useState } from "react";
import { ErrorBoundary } from "react-error-boundary";
import {
  GetPolicyTuningConfigParams,
  GetPolicyTuningConfigParamsResponse,
  getPolicyTuningHpaCurrentData,
  GetPolicyTuningHpaCurrentDataResponse,
} from "../../../api/fetcher";
import { components } from "../../../api/schema";
import CustomLegend from "../../../components/CustomLegend";
import DefaultFallback from "../../../components/DefaultFallback";
import { adjustedDayjs } from "../../../utils/dateAndTimeUtils";
import { ScaleOpsProduct } from "../../../utils/typesUtils";
import CalculatingHPAAlertBar from "./CalculatingHPAAlertBar";
import CostBarChart from "./CostBarChart";
import PredictableBadges from "./PredictableBadges";
import ReplicasBadges from "./ReplicasBadges";
import ReplicasChart from "./ReplicasChart";
import SaveAndAutomateButtons from "./SaveAndAutomateButtons";
import SelectHPAPolicy from "./SelectHPAPolicy";
import SelectViewPeriod from "./SelectViewPeriod";
import { HpaChartComponent } from "./UsageChart/UsageHpaChart";
import {
  displayNameHpaLegendFormatter,
  getReplicasComponentsStyle,
  useViewPeriodQueryParams,
  ViewPeriodOptions,
} from "./utils";

const TopSectionDivider = () => <div className="bg-border w-[1px] h-16" />;

const getCustomElementTooltip = (key: string): React.ReactNode => {
  const output: React.ReactNode = undefined;

  switch (key) {
    case "maxTriggerValueReplicas":
      return (
        <Typography variant="caption">
          <b>Maximum of all triggers</b>, without considering min replicas.
        </Typography>
      );
    case "triggerBasedReplicas:cpu":
      return (
        <Typography variant="caption">
          The <b>maximum replicas</b> calculated by the HPA, taking the upper limit from all triggers.
        </Typography>
      );
    default:
      break;
  }
  return output;
};

interface Props {
  selectedWorkload: components["schemas"]["UtilsWorkload"];
  fetchWorkloads: () => void;
  tuningParams: GetPolicyTuningConfigParamsResponse;
  setTuningParams: React.Dispatch<React.SetStateAction<GetPolicyTuningConfigParamsResponse>>;
  selectedChartComponents: HpaChartComponent[];
  setSelectedChartComponents: React.Dispatch<React.SetStateAction<HpaChartComponent[]>>;
  isAutomate: boolean;
  setIsAutomate: React.Dispatch<React.SetStateAction<boolean>>;
  selectedHPAPolicy: string | undefined;
  setSelectedHPAPolicy: (policy: string | undefined) => void;
  scaleOpsProduct?: ScaleOpsProduct;
}

const PolicyTuningHpa = memo(
  ({
    selectedWorkload,
    fetchWorkloads,
    tuningParams,
    setTuningParams,
    selectedChartComponents,
    setSelectedChartComponents,
    isAutomate,
    setIsAutomate,
    selectedHPAPolicy,
    setSelectedHPAPolicy,
    scaleOpsProduct,
  }: Props) => {
    const namespace = selectedWorkload.namespace;
    const name = `${selectedWorkload.type.toLocaleLowerCase()}-${selectedWorkload.workloadName}`;
    const getPolicyTuningConfigParams = GetPolicyTuningConfigParams();
    const [hpaChartComponents, setHpaChartComponents] = useState<Record<string, HpaChartComponent>>(
      {} as Record<string, HpaChartComponent>
    );
    const [, setSelectedViewPeriod] = useViewPeriodQueryParams();
    const policyTuningHpaCurrentData = getPolicyTuningHpaCurrentData();
    const { data: currentData, isLoading: isCurrentDataLoading } = useQuery<
      GetPolicyTuningHpaCurrentDataResponse,
      Error
    >(
      [policyTuningHpaCurrentData.queryKey, name, namespace],
      () => {
        return policyTuningHpaCurrentData.queryFn({
          name,
          namespace,
        });
      },
      {
        retry: 10,
        refetchOnWindowFocus: false,
      }
    );

    const { data: dataTunningParams } = useQuery<GetPolicyTuningConfigParamsResponse, Error>({
      queryKey: [getPolicyTuningConfigParams.queryKey, selectedWorkload.id, selectedHPAPolicy],
      queryFn: () =>
        getPolicyTuningConfigParams.queryFn({
          policyName: selectedHPAPolicy ?? "",
          namespace,
          name,
        }),
    });

    useEffect(() => {
      const isCreatedAtLessThan4Hours =
        selectedWorkload.createdAt?.length > 0 &&
        Number(selectedWorkload.createdAt) !== 0 &&
        adjustedDayjs().diff(adjustedDayjs(selectedWorkload.createdAt), "hour") < 4;

      setSelectedViewPeriod(isCreatedAtLessThan4Hours ? ViewPeriodOptions["4 hours"] : ViewPeriodOptions["2 weeks"]);
    }, []);

    useEffect(() => {
      if (dataTunningParams) {
        setTuningParams({
          cpuPolicyTuningParams: { ...dataTunningParams.cpuPolicyTuningParams },
          memoryPolicyTuningParams: { ...dataTunningParams.memoryPolicyTuningParams },
        });
      }
    }, [dataTunningParams]);

    const shouldShowCalculatingHPAMessage =
      !isCurrentDataLoading && (!currentData?.optimizationStrategy || currentData.optimizationStrategy.length === 0);

    return (
      <div className="flex flex-col gap-4 w-full">
        {shouldShowCalculatingHPAMessage && <CalculatingHPAAlertBar />}
        <div className="flex gap-6 p-4 items-center border rounded-lg border-border">
          <div className="w-fit flex gap-8">
            {selectedHPAPolicy && (
              <ErrorBoundary
                fallback={<DefaultFallback message="Failed to load Selected Policy. Please check your setup." />}
              >
                <SelectHPAPolicy
                  selectedHPAPolicy={selectedHPAPolicy}
                  setSelectedPolicy={setSelectedHPAPolicy}
                  selectedWorkload={selectedWorkload}
                />
              </ErrorBoundary>
            )}
            <ErrorBoundary
              fallback={<DefaultFallback message="Failed to load Selected View Period. Please check your setup." />}
            >
              <SelectViewPeriod />
            </ErrorBoundary>
          </div>
          <TopSectionDivider />
          <div className="grow">
            <div className="max-w-[500px]">
              <CostBarChart
                currentCost={currentData?.currentCost ?? 0}
                optimizedCost={currentData?.optimizedCost ?? 0}
              />
            </div>
          </div>
          <TopSectionDivider />
          <div className="flex flex-col w-fit justify-end gap-1.5">
            <ErrorBoundary
              fallback={<DefaultFallback message="Failed to load Cost Bar Chart. Please check your setup." />}
            >
              <PredictableBadges data={currentData} />
              <ReplicasBadges data={currentData} />
            </ErrorBoundary>
          </div>
        </div>
        <div>
          {tuningParams && selectedHPAPolicy && (
            <ErrorBoundary fallback={<DefaultFallback message="Failed to load Chart. Please check your setup." />}>
              <div className="border rounded-lg border-border">
                <ReplicasChart
                  selectedWorkload={selectedWorkload}
                  policyName={selectedHPAPolicy}
                  selectedChartComponents={selectedChartComponents}
                  setSelectedChartComponents={setSelectedChartComponents}
                  setHpaChartComponents={setHpaChartComponents}
                />
                <CustomLegend<HpaChartComponent>
                  selectedChartComponents={selectedChartComponents}
                  setSelectedChartComponents={setSelectedChartComponents}
                  componentStyle={getReplicasComponentsStyle(hpaChartComponents)}
                  ChartComponents={hpaChartComponents}
                  className="mt-1 pb-3"
                  displayNameFormatter={displayNameHpaLegendFormatter}
                  customElementTooltip={getCustomElementTooltip}
                  hasInfoIcon={(key) => ["maxTriggerValueReplicas", "triggerBasedReplicas:cpu"].includes(key)}
                  tooltipMaxWidth={() => 500}
                />
              </div>
            </ErrorBoundary>
          )}
        </div>
        <SaveAndAutomateButtons
          selectedWorkload={selectedWorkload}
          fetchWorkloads={fetchWorkloads}
          isAutomate={isAutomate}
          setIsAutomate={setIsAutomate}
          scaleOpsProduct={ScaleOpsProduct.HPA}
          selectedHPAPolicy={selectedHPAPolicy}
          autoFieldToUpdate={scaleOpsProduct === ScaleOpsProduct.HPA ? "auto" : "hpaAuto"}
        />
      </div>
    );
  }
);

export default PolicyTuningHpa;

import { SCALEOPS_COLORS } from "../../../colors";

export const ROW_DATA = "rowData";
export const SCHEDULE_BLOCKER = "ScheduleBlocker";

export type GraphPoint = Record<
  string,
  | string
  | number
  | {
      [key: string]:
        | {
            count?: number;
            nodes?: string[];
          }
        | undefined;
    }
  | undefined
>;

/** @barel22 Note: replace after @ofek7301 will provide the selected data */
export const CHART_COLORS = {
  totalOptimize: SCALEOPS_COLORS.greenPalette.totalOptimize,
  notEvictable: SCALEOPS_COLORS.greenPalette.notEvictable,
  notHealthy: SCALEOPS_COLORS.greenPalette.notHealthy,
  ownerless: SCALEOPS_COLORS.greenPalette.ownerless,
  kubeSystem: SCALEOPS_COLORS.greenPalette.kubeSystem,
};

import { useQuery } from "@tanstack/react-query";
import { GetHPAPolicies, GetHPAPoliciesResponse, getPolicies, GetPoliciesResponse } from "../../api/fetcher";
import { components } from "../../api/schema";
import { ScaleOpsProduct } from "../../utils/typesUtils";

type RightSizePolicies = { policies?: components["schemas"]["V1alpha1Policy"][] };
type HPAPolicies = { policies?: components["schemas"]["V1alpha1HpaPolicy"][] };
type Policies = RightSizePolicies | HPAPolicies | undefined;

const getUniquePolicyNames = (policies: Policies): string[] => {
  const polices = policies?.policies?.map((policy) => policy.metadata?.name || "").filter((name) => name !== "") || [];
  return Array.from(new Set(polices));
};

const policies = getPolicies();
const HPAPoliciesQuery = GetHPAPolicies();

interface Props {
  scaleOpsProduct: ScaleOpsProduct | undefined;
  excludeScheduledPolicies?: boolean;
}

const useGetUniquePolicyNamesByProduct = ({ scaleOpsProduct, excludeScheduledPolicies }: Props): string[] => {
  const isHpaProduct = scaleOpsProduct === ScaleOpsProduct.HPA;

  const { data: RightsizeData } = useQuery<GetPoliciesResponse, Error>({
    queryKey: [policies.queryKey],
    queryFn: policies.queryFn,
    enabled: !isHpaProduct,
  });

  const { data: HPAdata } = useQuery<GetHPAPoliciesResponse, Error>({
    queryKey: [HPAPoliciesQuery.queryKey],
    queryFn: () => HPAPoliciesQuery.queryFn(),
    enabled: isHpaProduct,
  });

  if (scaleOpsProduct === ScaleOpsProduct.HPA) {
    return getUniquePolicyNames(HPAdata);
  }

  const filteredRightsizeData = {
    ...RightsizeData,
    policies: RightsizeData?.policies?.filter(
      (policy) => !excludeScheduledPolicies || policy.spec?.type !== "Schedule"
    ),
  };

  return getUniquePolicyNames(filteredRightsizeData);
};

export default useGetUniquePolicyNamesByProduct;

import { Typography } from "@mui/material";
import useGetUserRole from "./useGetUserRole";
import Tooltip from "../components/Tooltip";
import InfoIcon from "../Icons/InfoIcon";

type Props = {
  groups?: string[] | undefined;
  name?: string | undefined;
  ssoName?: string;
};

const INFO_TOOLTIP_ENABLED = false;

const mapGroups = (groups: string[] | undefined) => {
  const viewers: string[] = [];
  const operators: string[] = [];
  groups?.forEach((group) => {
    if (group.split("-")[0].toLowerCase() === "operators") operators.push(group.split("-").slice(1).join("-"));
    else if (group.split("-")[0].toLowerCase() === "viewers") viewers.push(group.split("-").slice(1).join("-"));
  });
  return (
    <>
      {operators.length > 0 && (
        <>
          <b>Operator namespaces:</b>
          <ul>
            {operators.map((operator) => (
              <li>&nbsp;&nbsp;{operator}</li>
            ))}
          </ul>
        </>
      )}
      <br />
      {viewers.length > 0 && (
        <>
          <b>Viewer namespaces:</b>
          <ul>
            {viewers.map((viewer) => (
              <li>&nbsp;&nbsp;{viewer}</li>
            ))}
          </ul>
        </>
      )}
    </>
  );
};

const UserRole = ({ groups, name, ssoName }: Props) => {
  const { userRole } = useGetUserRole();

  if (!userRole) return null;

  return (
    <Typography variant="caption" className="opacity-50 flex">
      {INFO_TOOLTIP_ENABLED && ssoName === "token" && (
        <>
          <Tooltip
            className={"pr-[7px]"}
            title={
              <>
                K8S token
                <hr />
                <b>User:</b>
                <br />
                {name}
                <br />
                <br />
                {mapGroups(groups)}
              </>
            }
          >
            <InfoIcon width={14} height={14} />
          </Tooltip>
        </>
      )}
      Role: {userRole}
    </Typography>
  );
};

export default UserRole;

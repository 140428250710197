import SnapshotWrapper from "../components/SnapshotWrapper";
import MultipleCubesIcon from "../Icons/MultipleCubesIcon";
import RightSizingIcon from "../Icons/RightSizingIcon";
import UpAndDownCircleIcon from "../Icons/UpAndDownCircleIcon";
import { HAS_UNEVICTABLE_MENU } from "../utils/developmentFeatureFlags";
import useHasMultiProductLayoutWithReactQuery from "../utils/useHasMultiProductLayoutWithReactQuery";
import MainMenuItem, { MainMenuItemSize } from "./MainMenuItem";
import MenuHorizontalRule from "./MenuHorizontalRule";
import MultiMenu from "./MultiMenu";

type Props = {
  hpaOptimizationEnabled?: boolean;
};
const MultiProductMenu = ({ hpaOptimizationEnabled }: Props) => {
  const hasMultiProductLayout = useHasMultiProductLayoutWithReactQuery();

  return (
    <>
      <MenuHorizontalRule title="Optimization" />
      <MultiMenu
        baseUrl={{ name: "Pod Rightsizing", url: "/" }}
        urls={[
          { name: "Workloads", url: "/" },
          { name: "Policies", url: "/rightsize/policies" },
        ]}
        icon={<RightSizingIcon />}
        title="Pod Rightsizing"
        MainMenuItemSize={MainMenuItemSize.Small}
      />
      {hasMultiProductLayout && (
        <SnapshotWrapper wrappedType="menu" inactive={hpaOptimizationEnabled}>
          <MultiMenu
            baseUrl={{ name: "Predictive HPA", url: "/hpa/workloads" }}
            urls={[
              { name: "Workloads", url: "/hpa/workloads" },
              { name: "Policies", url: "/hpa/policies" },
            ]}
            icon={<UpAndDownCircleIcon />}
            title="Predictive HPA"
            MainMenuItemSize={MainMenuItemSize.Small}
            isBeta
          />
        </SnapshotWrapper>
      )}
      {HAS_UNEVICTABLE_MENU && <MainMenuItem icon={<MultipleCubesIcon />} title="Pod Placement" to="/unevictable" />}
      <MenuHorizontalRule />
    </>
  );
};

export default MultiProductMenu;

import { useFormikContext } from "formik";
import FormInput from "../../../../components/formComponents/FormInput";
import FormTitleAndDescription from "../../../../components/formComponents/FormTitleAndDescription";
import { HPA_SECTION_CLASS_NAME } from "../utils";

interface Props {
  isCustomizedPolicy?: boolean;
}

const MinReplicasAllowed = ({ isCustomizedPolicy }: Props) => {
  const { values } = useFormikContext<{
    workloadsToOptimize: string;
    hasHPAenabled: boolean;
  }>();

  const isDisabled = !isCustomizedPolicy || !values.hasHPAenabled;

  return (
    <div className={HPA_SECTION_CLASS_NAME}>
      <FormTitleAndDescription
        title="Min replicas boundary"
        description={"Set the minimum replicas allowed for the workload."}
      />
      <FormInput name="hpaMinReplicas" type="number" required max={100000} disabled={isDisabled} className="w-[80px]" />
    </div>
  );
};

export default MinReplicasAllowed;

import { useQuery } from "@tanstack/react-query";
import { useEffect, useState } from "react";
import { GetScheduleBlockersGraph, GetScheduleBlockersGraphResponse } from "../../../api/fetcher";
import { PAGE_CONTENT_CLASS_NAME } from "../../../utils/styleUtils";
import ScheduleBlockersGraph from "./ScheduleBlockersGraph";
import { GraphPoint } from "./utils";

const { queryKey, queryFn } = GetScheduleBlockersGraph();

const ScheduleBlockersGraphContainer = () => {
  const [graphData, setGraphData] = useState<GraphPoint[] | undefined>([]);

  const { data, isError } = useQuery<GetScheduleBlockersGraphResponse, Error>({
    queryKey: [queryKey],
    queryFn: () => queryFn(),
  });

  if (isError) {
    console.log("Error fetching schedule blockers graph data");
    return null;
  }

  useEffect(() => {
    if (data) {
      const newGraphData: GraphPoint[] | undefined = data?.data?.map((point) => {
        const name = String(point.name);
        const rowData = point.reasons;
        const reasons: Record<string, number> = {};

        for (const [key, value] of Object.entries(point?.reasons ?? {})) {
          if (key === "name") continue;
          reasons[key] = Number((value as { count: number })?.count ?? 0);
        }

        return {
          ...reasons,
          name,
          rowData,
        };
      });

      setGraphData(newGraphData);
    }
  }, [data]);

  return (
    <div className={PAGE_CONTENT_CLASS_NAME}>
      <ScheduleBlockersGraph data={graphData ?? []} />
    </div>
  );
};

export default ScheduleBlockersGraphContainer;

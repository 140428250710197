import FormTitleAndDescription from "../../../components/formComponents/FormTitleAndDescription";
import HistoryCollection from "./HistoryCollection";
import PolicyField from "./PolicyField";

interface Props {
  isCustomizedPolicy?: boolean;
}

const DefaultPolicy = ({ isCustomizedPolicy }: Props) => {
  return (
    <div className="flex flex-col gap-4 border-b border-strongBorder pb-7">
      <FormTitleAndDescription
        title="Default policy"
        description="This policy will be applied in the absence of an override policies."
      />
      <div className="w-full max-w-[34.125rem] flex items-end gap-2.5">
        <PolicyField label="Policy name" name="defaultPolicy" isCustomizedPolicy={isCustomizedPolicy} />
        <HistoryCollection name="displayDefaultHistoryCollectionInput" disabled />
      </div>
    </div>
  );
};
export default DefaultPolicy;

import { MenuItem, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import MagicWand from "../../../Icons/MagicWand";
import { getPolicies, GetPoliciesResponse } from "../../../api/fetcher";
import { SCALEOPS_COLORS } from "../../../colors";
import SingleSelect from "../../../components/SingleSelect";
import Tooltip from "../../../components/Tooltip";
import useHpaOptimizationEnabled from "../../../components/WorkloadStatusByNamespace/useHpaOptimizationEnabled";
import { enableFilterByUrlParam, FilterByUrlParam } from "../../../utils/queryParams";
import useIsReadyOnlyFrontEnd from "../../../utils/useIsReadyOnlyFrontEnd";
import { PolicyType } from "../../Policies/utils";
import PolicyNameTooltip from "./PolicyNameTooltip";
import { Policy } from "./utils";

interface Props {
  selectedPolicy: Policy | undefined;
  setSelectedPolicy: (policy: Policy | undefined) => void;
  isOverridePolicy: boolean;
  smartPolicy?: boolean;
  smartPolicyName?: string;
  smartPolicyWorkloadType?: string;
  isPolicyForced?: boolean;
  isInternal?: boolean;
}

const ENABLE_HPA_RECOMMENDATION = enableFilterByUrlParam(FilterByUrlParam.ENABLE_HPA_RECOMMENDATION);

const SelectPolicy = ({
  selectedPolicy,
  setSelectedPolicy,
  isOverridePolicy,
  smartPolicyName,
  smartPolicyWorkloadType,
  isPolicyForced,
  isInternal,
}: Props) => {
  const isReadyOnlyFrontEnd = useIsReadyOnlyFrontEnd();
  const policies = getPolicies();
  const { data } = useQuery<GetPoliciesResponse, Error>({
    queryKey: [policies.queryKey],
    queryFn: policies.queryFn,
  });

  const setPolicyBySelectedName = (props: string | undefined) => {
    if (props === undefined) props = "";
    setSelectedPolicy({ name: props, displayName: props });
  };
  const hpaOptimizationEnabled = useHpaOptimizationEnabled();

  const displayValue = selectedPolicy?.displayName ?? selectedPolicy?.name;
  const isSmartPolicy = smartPolicyName === displayValue;

  const displayPolicyName =
    selectedPolicy?.displayName && isOverridePolicy ? selectedPolicy?.displayName : selectedPolicy?.name;

  return (
    <Tooltip
      title={
        <PolicyNameTooltip
          displayPolicyName={String(displayPolicyName)}
          isInternal={isInternal}
          smartPolicyName={smartPolicyName}
          smartPolicyWorkloadType={smartPolicyWorkloadType}
          isPolicyForced={isPolicyForced}
        />
      }
      disabled={!isReadyOnlyFrontEnd && !isPolicyForced}
      maxWidth={500}
    >
      <SingleSelect<string | undefined>
        sx={{
          color: isSmartPolicy ? "black" : undefined,
          background: isSmartPolicy ? SCALEOPS_COLORS.guideline.lightPurple : SCALEOPS_COLORS.guideline.lightBlue,
        }}
        disabled={isReadyOnlyFrontEnd || isPolicyForced}
        label="Policy name"
        className="bg-[#efefff5b]"
        labelClassName="text-guidelines-darkBlue"
        arrowIconColor="black"
        selectedColor="black"
        renderValue={() => {
          return (
            <Typography variant="body2" fontWeight={500} className="w-full truncate pt-[1px] flex items-center gap-2">
              {isSmartPolicy && <MagicWand width={14} height={14} className="text-guideline-darkBlue" />}
              <span className="truncate">{displayValue}</span>
            </Typography>
          );
        }}
        selected={selectedPolicy?.displayName && isOverridePolicy ? selectedPolicy?.displayName : selectedPolicy?.name}
        setSelected={setPolicyBySelectedName}
        renderOptionsFunc={() =>
          data?.policies
            ?.filter(
              (policy) =>
                policy.metadata?.name != PolicyType.ProductionHpa || hpaOptimizationEnabled || ENABLE_HPA_RECOMMENDATION
            )
            .map((policy) => (
              <MenuItem key={policy.metadata?.name} value={policy.metadata?.name}>
                <Typography variant="body2" fontWeight={500} className="flex items-center gap-2">
                  {policy.metadata?.name}
                  {smartPolicyName === policy.metadata?.name && (
                    <div className="flex gap-2 bg-guideline-lightPurple px-2 py-1 rounded-full items-center ml-2">
                      <MagicWand color="black" width={18} height={18} />
                      <p className="text-black text-[12px] bolder font-light">Auto-Detected</p>
                    </div>
                  )}
                </Typography>
              </MenuItem>
            ))
        }
      />
    </Tooltip>
  );
};

export default SelectPolicy;

import ClearIcon from "@mui/icons-material/Clear";
import { CircularProgress, Typography } from "@mui/material";
import { useQuery } from "@tanstack/react-query";
import { Form, Formik } from "formik";
import { useEffect, useState } from "react";
import {
  GetBetaFeaturesQuery,
  GetBetaFeaturesResponse,
  GetCustomNamespaceLabels,
  GetCustomNamespaceLabelsResponse,
  GetIgnoredNamespaces,
  GetIgnoredNamespacesResponse,
  getNamespaces,
  GetNamespacesResponse,
} from "../../api/fetcher";
import useGetFreeTrialData from "../../MainMenu/useGetFreeTrialData";
import { HAS_HPA_ENABLED } from "../../utils/developmentFeatureFlags";
import Button from "../Button";
import CustomSelectedFilterChip from "../CustomSelectedFilterChip";
import FormSwitch from "../formComponents/FormSwitch";
import FormTitleAndDescription from "../formComponents/FormTitleAndDescription";
import MultiSelect from "../MultiSelect";
import useGetAllNamespacesLabels from "../WorkloadStatusByNamespace/OverviewHooks/useGetAllNamespacesLabels";
import useCustomNamespaceLabels from "./useCustomNamespaceLabels";
import useIgnoreNamespaces from "./useIgnoreNamespaces";
import useSaveBetaFeatures from "./useSaveBetaFeatures";

interface Props {
  disabled?: boolean;
}

const { queryKey: betaFeaturesQueryKey, queryFn: betaFeaturesQueryFn } = GetBetaFeaturesQuery();
const { queryKey: ignoreNamespacesQueryKey, queryFn: ignoreNamespacesQueryFn } = GetIgnoredNamespaces();
const { queryKey: customNamespaceLabelsQueryKey, queryFn: customNamespaceLabelsQueryFn } = GetCustomNamespaceLabels();

const GeneralSettingsTab = ({ disabled }: Props) => {
  const freeTrialData = useGetFreeTrialData();
  const betaFeatures = useSaveBetaFeatures();
  const ignoreNamespaces = useIgnoreNamespaces();
  const customNamespaceLabels = useCustomNamespaceLabels();
  const namespaces = getNamespaces();
  const [selectedNamespaceFilters, setSelectedNamespaceFilters] = useState<string[]>([]);
  const [selectedCustomNamespaceLabels, setSelectedCustomNamespaceLabels] = useState<{
    "default-auto": { key?: string; value?: string }[];
    "default-auto-regex": { key?: string; value?: string }[];
    "exclude-automation": { key?: string; value?: string }[];
    "exclude-automation-regex": { key?: string; value?: string }[];
  }>({
    "default-auto": [],
    "default-auto-regex": [],
    "exclude-automation": [],
    "exclude-automation-regex": [],
  });
  const allNamespaceLabels = useGetAllNamespacesLabels();
  const { data: nsData } = useQuery<GetNamespacesResponse, Error>([namespaces.queryKey], () => namespaces.queryFn({}));

  const { data: betaFeaturesData, isLoading: betaFeaturesIsLoading } = useQuery<GetBetaFeaturesResponse, Error>({
    queryKey: [betaFeaturesQueryKey, "for-general-settings"],
    queryFn: betaFeaturesQueryFn,
  });

  const { data: ignoreNamespacesData, isLoading: ignoreNamespacesIsLoading } = useQuery<
    GetIgnoredNamespacesResponse,
    Error
  >({
    queryKey: [ignoreNamespacesQueryKey, "for-ignored-ns"],
    queryFn: ignoreNamespacesQueryFn,
  });

  const { data: customNamespaceLabelsData, isLoading: customNamespaceIsLoading } = useQuery<
    GetCustomNamespaceLabelsResponse,
    Error
  >({
    queryKey: [customNamespaceLabelsQueryKey, "for-custom-ns-labels"],
    queryFn: customNamespaceLabelsQueryFn,
  });

  const namespacesOptions = [
    ...new Set(
      Object.values(nsData?.namespaces || [])
        .map((namespace) => namespace?.metadata?.name || "")
        .concat(selectedNamespaceFilters)
        .sort((a, b) => a.localeCompare(b))
    ),
  ];

  if (betaFeaturesIsLoading || ignoreNamespacesIsLoading || customNamespaceIsLoading) {
    return (
      <div className="bg-white flex items-center justify-center w-full">
        <CircularProgress />
      </div>
    );
  }

  return (
    <Formik
      initialValues={{
        betaFeaturesEnabled: !!betaFeaturesData?.enabled,
        hpaOptimization: !!betaFeaturesData?.betaFeatures?.hpaOptimization,
        nodeCapacityConfiguration: !!betaFeaturesData?.betaFeatures?.nodeCapacityConfiguration,
        nodeConsolidation: !!betaFeaturesData?.betaFeatures?.nodeConsolidation,
        namespaces: selectedNamespaceFilters,
      }}
      onSubmit={(values) => {
        const isBetaFeaturesChanged =
          betaFeaturesData?.betaFeatures &&
          (values.nodeCapacityConfiguration !== betaFeaturesData.betaFeatures.nodeCapacityConfiguration ||
            values.nodeConsolidation !== betaFeaturesData.betaFeatures.nodeConsolidation);
        if (isBetaFeaturesChanged) {
          betaFeatures.mutate({
            enabled: true,
            betaFeatures: {
              hpaOptimization: !!values.hpaOptimization,
              nodeCapacityConfiguration: !!values.nodeCapacityConfiguration,
              nodeConsolidation: !!values.nodeConsolidation,
            },
          });
        }
        const isNsListChanged =
          selectedNamespaceFilters.length !== ignoreNamespacesData?.namespaces?.length ||
          selectedNamespaceFilters.some((value, index) => value !== ignoreNamespacesData?.namespaces?.[index]);
        if (isNsListChanged) {
          ignoreNamespaces.mutate({
            namespaces: selectedNamespaceFilters,
          });
        }

        const isCustomNamespaceLabelsChanged =
          selectedCustomNamespaceLabels["default-auto"].length !==
            customNamespaceLabelsData?.["default-auto"]?.length ||
          selectedCustomNamespaceLabels["exclude-automation"].length !==
            customNamespaceLabelsData?.["exclude-automation"]?.length ||
          selectedCustomNamespaceLabels["default-auto-regex"]?.length !==
            customNamespaceLabelsData?.["default-auto-regex"]?.length ||
          selectedCustomNamespaceLabels["exclude-automation-regex"]?.length !==
            customNamespaceLabelsData?.["exclude-automation-regex"]?.length ||
          selectedCustomNamespaceLabels["default-auto"].some(
            (label, index) => label.key !== customNamespaceLabelsData?.["default-auto"]?.[index]?.key
          ) ||
          selectedCustomNamespaceLabels["default-auto-regex"]?.some(
            (label, index) => label.value !== customNamespaceLabelsData?.["default-auto-regex"]?.[index]?.value
          ) ||
          selectedCustomNamespaceLabels["exclude-automation"].some(
            (label, index) => label.key !== customNamespaceLabelsData?.["exclude-automation"]?.[index]?.key
          ) ||
          selectedCustomNamespaceLabels["exclude-automation-regex"]?.some(
            (label, index) => label.value !== customNamespaceLabelsData?.["exclude-automation-regex"]?.[index]?.value
          );

        if (isCustomNamespaceLabelsChanged) {
          customNamespaceLabels.mutate({
            "default-auto": selectedCustomNamespaceLabels["default-auto"],
            "default-auto-regex": selectedCustomNamespaceLabels["default-auto-regex"],
            "exclude-automation": selectedCustomNamespaceLabels["exclude-automation"],
            "exclude-automation-regex": selectedCustomNamespaceLabels["exclude-automation-regex"],
          });
        }
      }}
    >
      {(formik) => {
        const { values } = formik;
        const isDisabled = disabled || !values.betaFeaturesEnabled;

        useEffect(() => {
          setSelectedNamespaceFilters(ignoreNamespacesData?.namespaces || []);
        }, []);
        useEffect(() => {
          setSelectedCustomNamespaceLabels({
            "default-auto": customNamespaceLabelsData?.["default-auto"] ?? [],
            "default-auto-regex": customNamespaceLabelsData?.["default-auto-regex"] ?? [],
            "exclude-automation": customNamespaceLabelsData?.["exclude-automation"] ?? [],
            "exclude-automation-regex": customNamespaceLabelsData?.["exclude-automation-regex"] ?? [],
          });
        }, [customNamespaceLabelsData]);
        return (
          <Form className="flex flex-col">
            {!freeTrialData && (
              <div className="border-b w-full pb-10">
                <div className="flex items-center">
                  <FormTitleAndDescription
                    title="Beta Features"
                    description="Explore ScaleOps new functionality by enabling one or more of the following features."
                  />
                </div>
                {HAS_HPA_ENABLED && (
                  <div className="flex items-center">
                    <FormSwitch name="hpaOptimization" disabled={isDisabled} className="ml-[-9px]" />
                    <Typography variant="body2">HPA optimization</Typography>
                  </div>
                )}
                <div className="flex items-center">
                  <FormSwitch name="nodeCapacityConfiguration" disabled={isDisabled} className="ml-[-9px]" />
                  <Typography variant="body2">Cluster headroom</Typography>
                </div>
                <div className="flex items-center">
                  <FormSwitch name="nodeConsolidation" disabled={isDisabled} className="ml-[-9px]" />
                  <Typography variant="body2">Node consolidation</Typography>
                </div>
              </div>
            )}
            <div className="border-b w-full py-10 flex flex-col gap-3">
              <FormTitleAndDescription
                title="Ignored Namespaces"
                description="Select namespaces to exclude from the platform. These namespaces will be ignored for all operations and will not be visible to users."
              />
              <MultiSelect
                isSearchable
                hasVirtualizedList
                label={<span className="text-[14px]">Namespaces</span>}
                wrapperClassName="w-[220px]"
                className="w-[220px]"
                selected={selectedNamespaceFilters}
                setSelected={(options) => {
                  setSelectedNamespaceFilters(options as string[]);
                }}
                options={namespacesOptions ?? []}
                fontSize="12px"
                renderValue={(selected) => `Selected (${selected.length}) `}
                disableTooltip
                disabled={disabled}
              />
              <div className="flex justify-start item-center gap-2 flex-wrap max-h-[140px] overflow-auto">
                {selectedNamespaceFilters?.map((ns) => (
                  <div className="inline-grid">
                    <CustomSelectedFilterChip
                      className="max-w-[200px] truncate bg-text-lightBlack hover:bg-strongerBorder text-[#fff]"
                      label={<>{ns ?? ""}</>}
                      onClick={() => {
                        if (disabled) return;
                        setSelectedNamespaceFilters((s) => s?.filter((l) => l !== ns));
                      }}
                      tooltipContent={<>namespaces "{ns ?? ""}".</>}
                      key={`ns (${ns})`}
                      hasTooltip
                      clearIcon={<ClearIcon sx={{ fontSize: 15, padding: "0px", color: "whitesmoke" }} />}
                    />
                  </div>
                ))}
              </div>
            </div>
            <div className="flex flex-col w-full py-10 justify-between item-center gap-[30px]">
              <Typography variant="body1" fontWeight="bold">
                Custom Namespace Labels
              </Typography>
              <div className="flex grow flex-col gap-3">
                <Typography variant="body2" fontWeight={600} className="flex items-center gap-1">
                  Select labels to automate
                </Typography>
                <FormTitleAndDescription description="ScaleOps will automate workloads in namespaces that have at least one of the following labels." />
                <MultiSelect
                  isSearchable
                  hasVirtualizedList
                  label={<span className="text-[14px]">Labels</span>}
                  className="w-[220px]"
                  selected={
                    selectedCustomNamespaceLabels["default-auto"].map(
                      (label: { key?: string; value?: string }) => `${label.key ?? ""}=${label.value ?? ""}`
                    ) ?? []
                  }
                  setSelected={(options) => {
                    setSelectedCustomNamespaceLabels((prev) => ({
                      ...prev,
                      "default-auto": options.map((option) => {
                        if (!option) return { key: "", value: "" };
                        const [key, value] = option.split("=");
                        return { key: key ?? "", value: value ?? "" };
                      }),
                    }));
                  }}
                  options={allNamespaceLabels ?? []}
                  fontSize="12px"
                  renderValue={(selected) => `Selected (${selected.length}) `}
                  disableTooltip
                  disabled={disabled}
                />
                <div className="flex justify-start item-center gap-2 flex-wrap max-h-[140px] overflow-auto">
                  {selectedCustomNamespaceLabels["default-auto"]?.map((label: { key?: string; value?: string }) => (
                    <CustomSelectedFilterChip
                      className="max-w-[200px] truncate bg-text-lightBlack hover:bg-strongerBorder text-[#fff]"
                      label={
                        <>
                          {label.key}={label.value}
                        </>
                      }
                      onClick={() => {
                        if (disabled) return;
                        setSelectedCustomNamespaceLabels((prev) => ({
                          ...prev,
                          "default-auto": prev["default-auto"].filter(
                            (l) => l.key !== label.key || l.value !== label.value
                          ),
                        }));
                      }}
                      tooltipContent={
                        <>
                          labels "{label.key}={label.value}".
                        </>
                      }
                      key={`label (${label.key ?? ""}=${label.value ?? ""})`}
                      hasTooltip
                      clearIcon={<ClearIcon sx={{ fontSize: 15, padding: "0px", color: "whitesmoke" }} />}
                    />
                  ))}
                  {selectedCustomNamespaceLabels["default-auto-regex"]?.map(
                    (label: { key?: string; value?: string }) => (
                      <div className="inline-grid">
                        <CustomSelectedFilterChip
                          className="max-w-[200px] truncate bg-text-lightBlack hover:bg-strongerBorder text-[#fff]"
                          label={
                            <>
                              {label?.key}={label?.value}
                            </>
                          }
                          onClick={() => {
                            if (disabled) return;
                            setSelectedCustomNamespaceLabels((prev) => ({
                              ...prev,
                              "default-auto-regex": prev["default-auto-regex"].filter(
                                (l) => l.key !== label?.key || l.value !== label?.value
                              ),
                            }));
                          }}
                          tooltipContent={
                            <>
                              labels "{label?.key}={label?.value}".
                            </>
                          }
                          key={`label (${label?.key ?? ""}=${label?.value ?? ""})`}
                          hasTooltip
                          clearIcon={<ClearIcon sx={{ fontSize: 15, padding: "0px", color: "whitesmoke" }} />}
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
              <div className="flex grow flex-col gap-3">
                <Typography variant="body2" fontWeight={600} className="flex items-center gap-1">
                  Select labels to exclude from automation
                </Typography>
                <FormTitleAndDescription description="ScaleOps will exclude automation for workloads in namespaces that have at least one of the following labels." />
                <MultiSelect
                  isSearchable
                  hasVirtualizedList
                  label={<span className="text-[14px]">Labels</span>}
                  wrapperClassName="w-[220px]"
                  className="w-[220px]"
                  selected={
                    selectedCustomNamespaceLabels["exclude-automation"].map(
                      (label: { key?: string; value?: string }) => `${label.key ?? ""}=${label.value ?? ""}`
                    ) ?? []
                  }
                  setSelected={(options) => {
                    setSelectedCustomNamespaceLabels((prev) => ({
                      ...prev,
                      "exclude-automation": options.map((option) => {
                        if (!option) return { key: "", value: "" };
                        const [key, value] = option.split("=");
                        return { key: key ?? "", value: value ?? "" };
                      }),
                    }));
                  }}
                  options={allNamespaceLabels ?? []}
                  fontSize="12px"
                  renderValue={(selected) => `Selected (${selected.length}) `}
                  disableTooltip
                  disabled={disabled}
                />

                <div className="flex justify-start item-center gap-2 flex-wrap max-h-[140px] overflow-auto">
                  {selectedCustomNamespaceLabels["exclude-automation"]?.map(
                    (label: { key?: string; value?: string }) => (
                      <div className="inline-grid">
                        <CustomSelectedFilterChip
                          className="max-w-[200px] truncate bg-text-lightBlack hover:bg-strongerBorder text-[#fff]"
                          label={
                            <>
                              {label.key}={label.value}
                            </>
                          }
                          onClick={() => {
                            if (disabled) return;
                            setSelectedCustomNamespaceLabels((prev) => ({
                              ...prev,
                              "exclude-automation": prev["exclude-automation"].filter(
                                (l: { key?: string; value?: string }) => l.key !== label.key || l.value !== label.value
                              ),
                            }));
                          }}
                          tooltipContent={
                            <>
                              labels "{label.key}={label.value}".
                            </>
                          }
                          key={`label (${label.key ?? ""}=${label.value ?? ""})`}
                          hasTooltip
                          clearIcon={<ClearIcon sx={{ fontSize: 15, padding: "0px", color: "whitesmoke" }} />}
                        />
                      </div>
                    )
                  )}
                  {selectedCustomNamespaceLabels["exclude-automation-regex"]?.map(
                    (label: { key?: string; value?: string }) => (
                      <div className="inline-grid">
                        <CustomSelectedFilterChip
                          className="max-w-[200px] truncate bg-text-lightBlack hover:bg-strongerBorder text-[#fff]"
                          label={
                            <>
                              {label?.key}={label?.value}
                            </>
                          }
                          onClick={() => {
                            if (disabled) return;
                            setSelectedCustomNamespaceLabels((prev) => ({
                              ...prev,
                              "exclude-automation-regex": prev["exclude-automation-regex"].filter(
                                (l: { key?: string; value?: string }) =>
                                  l.key !== label?.key || l.value !== label?.value
                              ),
                            }));
                          }}
                          tooltipContent={
                            <>
                              labels "{label?.key}={label?.value}".
                            </>
                          }
                          key={`label (${label?.key ?? ""}=${label?.value ?? ""})`}
                          hasTooltip
                          clearIcon={<ClearIcon sx={{ fontSize: 15, padding: "0px", color: "whitesmoke" }} />}
                        />
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>
            <div className="flex py-1 w-full justify-end">
              <Button type="submit" label="Save" disabled={disabled} />
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};
export default GeneralSettingsTab;

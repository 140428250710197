import { useFormikContext } from "formik";
import { components } from "../../../api/schema";
import FormInput from "../../../components/formComponents/FormInput";
import FormSingleSelect from "../../../components/formComponents/FormSingleSelect";
import FormTitleAndDescription from "../../../components/formComponents/FormTitleAndDescription";
import * as policyUtils from "../../../utils/policyUtils";
import { shouldDisableDefaultPolicyInputs } from "../utils";

const FORM_SINGLE_SELECT_CLASS_NAME = "w-[235px]";
const FORM_SINGLE_WIDTH = 235;

const RatioInfo = () => (
  <>
    Set the <b>limit / request</b> ratio. (&#8805; 1)
  </>
);

export enum LimitStrategyItems {
  KeepLimit = "Keep original limit",
  NoLimit = "Set no limit",
  EqualsToRequest = "Set limit as request",
  SetLimit = "Set limit value",
  KeepLimitRequestRatio = "Keep limit request ratio",
  SetLimitRequestRatio = "Set limit request ratio",
}

const getOptions = (): [string, string][] => Object.entries(LimitStrategyItems).map((entity) => [entity[1], entity[1]]);

interface Props {
  isCustomizedPolicy?: boolean;
  defaultPolicy?: components["schemas"]["V1alpha1Policy"] | undefined;
}

const LimitsStrategy = ({ isCustomizedPolicy, defaultPolicy }: Props) => {
  const { values, setValues } = useFormikContext<{
    limitStrategyCpu?: string;
    limitStrategyMemory?: string;
    cpuLimitsSetLimitRequestRatioValue?: number;
    memoryLimitsSetLimitRequestRatioValue?: number;
    cpuLimitsKeepLimitRequestRatioValue?: boolean;
    memoryLimitsKeepLimitRequestRatioValue?: boolean;
  }>();

  const policyStrategyOptions = getOptions();

  const defaultLimitStrategyCpu = isCustomizedPolicy ? policyUtils.policyCpuLimitStrategy(defaultPolicy) : undefined;

  const defaultLimitStrategyMemory = isCustomizedPolicy
    ? policyUtils.policyMemoryLimitStrategy(defaultPolicy)
    : undefined;

  return (
    <div className="flex flex-col gap-4 py-10 border-b border-strongBorder">
      <FormTitleAndDescription title="Limit strategy" description="Define the strategy for defining limits." />
      <div className="flex gap-6">
        <div className="flex flex-col gap-2 w-fit relative items-start">
          <FormSingleSelect
            label="CPU"
            name="limitStrategyCpu"
            className={FORM_SINGLE_SELECT_CLASS_NAME}
            options={policyStrategyOptions}
            defaultValue={defaultLimitStrategyCpu}
            areOptionsDisabled={!isCustomizedPolicy}
            disabled={!isCustomizedPolicy && shouldDisableDefaultPolicyInputs}
            additionalOnChange={(value) => {
              setValues({
                ...values,
                limitStrategyCpu: String(value),
                cpuLimitsSetLimitRequestRatioValue: value === LimitStrategyItems.SetLimitRequestRatio ? 1 : undefined,
              });
            }}
          />
          {values.limitStrategyCpu === LimitStrategyItems.SetLimit && (
            <FormInput
              label="CPU limit"
              name="cpuLimitsSetLimitValue"
              inputWidth={235}
              type="number"
              disabled={!isCustomizedPolicy && shouldDisableDefaultPolicyInputs}
              disableChange={!isCustomizedPolicy}
            />
          )}
          {values.limitStrategyCpu === LimitStrategyItems.SetLimitRequestRatio && (
            <FormInput
              label="CPU limit request ratio"
              info={<RatioInfo />}
              name="cpuLimitsSetLimitRequestRatioValue"
              inputWidth={FORM_SINGLE_WIDTH}
              type="number"
              disabled={!isCustomizedPolicy && shouldDisableDefaultPolicyInputs}
              disableChange={!isCustomizedPolicy}
            />
          )}
        </div>
        <div className="flex flex-col gap-2 w-fit relative items-start">
          <FormSingleSelect
            label="Memory"
            name="limitStrategyMemory"
            className={FORM_SINGLE_SELECT_CLASS_NAME}
            defaultValue={defaultLimitStrategyMemory}
            options={policyStrategyOptions}
            areOptionsDisabled={!isCustomizedPolicy}
            disabled={!isCustomizedPolicy && shouldDisableDefaultPolicyInputs}
            additionalOnChange={(value) => {
              setValues({
                ...values,
                limitStrategyMemory: String(value),
                memoryLimitsSetLimitRequestRatioValue:
                  value === LimitStrategyItems.SetLimitRequestRatio ? 1 : undefined,
              });
            }}
          />
          {values.limitStrategyMemory === LimitStrategyItems.SetLimit && (
            <FormInput
              label="Memory limit (GiB)"
              name="memoryLimitsSetLimitValue"
              inputWidth={FORM_SINGLE_WIDTH}
              type="number"
              disabled={!isCustomizedPolicy && shouldDisableDefaultPolicyInputs}
              disableChange={!isCustomizedPolicy}
            />
          )}
          {values.limitStrategyMemory === LimitStrategyItems.SetLimitRequestRatio && (
            <FormInput
              label="Memory limit request ratio"
              inputWidth={FORM_SINGLE_WIDTH}
              info={<RatioInfo />}
              name="memoryLimitsSetLimitRequestRatioValue"
              type="number"
              disabled={!isCustomizedPolicy && shouldDisableDefaultPolicyInputs}
              disableChange={!isCustomizedPolicy}
            />
          )}
        </div>
      </div>
    </div>
  );
};
export default LimitsStrategy;

import { Typography } from "@mui/material";
import dayjs from "dayjs";
import { downsample } from "downsample-lttb-ts";
import React, { useEffect, useState } from "react";
import {
  Area,
  CartesianGrid,
  ComposedChart,
  Line,
  ReferenceArea,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";
import { ObjectParam, useQueryParam } from "use-query-params";
import { getTimeFormatFromEpochAndPeriodInHours } from "../../../../utils/formatterUtils";
import { NO_OUTLINE } from "../../../../utils/styleUtils";
import { TooltipTrigger } from "../../../Analytics/AnalyticsV2/Graphs/hooks/useFreezeTooltip";
import { FrozenTooltipType } from "../../../Analytics/AnalyticsV2/Graphs/hooks/utils";
import { ChartComponents, POLICY_TUNING_DATES_URL_PARAM, useViewPeriodQueryParams } from "../utils";
import CustomTooltip from "./CustomTooltip";
import GraphLoading from "./GraphLoading";
import Styles, { AverageUsageColor, MaxUsageColor, PercentileUsageColor } from "./Styles";
import SnapshotChip from "../../../../components/SnapshotChip";
import SnapshotWrapper from "../../../../components/SnapshotWrapper";

const HAS_DOWN_SAMPLED_DATA = false;
const LIMIT_DEDUCER_TO_REQUESTS_LINES_ONLY = true;
const AVERAGE_USAGE_STROKE_WIDTH = 1;
const REGULAR_STROKE_WIDTH = 2;
const BOLD_STROKE_WIDTH = 2.5;
const DOWN_SAMPLED_DATA_LIMIT = 300;

type GraphDataPointType = {
  timestamp: string | undefined;
  podsAvgUsage: string | undefined;
  podsMaxUsage: string | undefined;
  podsP90Usage: string | undefined;
  currentRequest: string | undefined;
  originRequest: string | undefined;
  currentLimit: string | undefined;
  recommendedRequest: string | undefined;
  recommendedLimit: string | undefined;
  maxAllowed?: string;
  minAllowed?: string;
};

const defaultYTickFormatter = (tick: string | number) => {
  const value = Number(tick);
  if (value === 0) return "0";
  const roundedValue = Math.round(value * 100) / 100;
  return String(roundedValue);
};

const getDownSampledData = (
  graphData: {
    originalTimestamp: string | undefined;
    timestamp: number;
    podsAvgUsage: number;
    podsMaxUsage: number;
    podsP90Usage: number;
    currentRequest: number;
    originRequest: number;
    currentLimit: number;
    recommendedRequest: number;
    recommendedLimit: number;
    minAllowed: number;
    maxAllowed: number;
  }[]
) => {
  const firstDataPoint = graphData[0];
  const lastDataPoint = graphData[graphData.length - 1];
  const graphDataWithoutFirstAndLast = graphData.slice(1, graphData.length - 1);
  const timestampAndUsage = graphDataWithoutFirstAndLast
    ?.map((dataPoint) => [Number(dataPoint.timestamp), Number(dataPoint.podsAvgUsage)])
    ?.filter((dataPoint) => !isNaN(Number(dataPoint[1])));

  const downSampledTimestampAndUsage =
    timestampAndUsage &&
    downsample({
      series: timestampAndUsage,
      threshold: DOWN_SAMPLED_DATA_LIMIT - 2,
    });

  const downSampledEpochs = downSampledTimestampAndUsage?.map((dataPoint) => dataPoint[0]);

  const downSampledGraphData = graphData?.filter((dataPoint) => downSampledEpochs.includes(dataPoint.timestamp));

  downSampledGraphData.unshift(firstDataPoint);
  downSampledGraphData.push(lastDataPoint);

  return downSampledGraphData;
};

type GraphDataType = GraphDataPointType[];

const getDeducedData = (data: GraphDataType) => {
  const lastDefined: GraphDataPointType = {
    timestamp: undefined,
    podsAvgUsage: undefined,
    podsMaxUsage: undefined,
    podsP90Usage: undefined,
    currentRequest: undefined,
    originRequest: undefined,
    currentLimit: undefined,
    recommendedRequest: undefined,
    recommendedLimit: undefined,
    minAllowed: undefined,
    maxAllowed: undefined,
  };

  return data?.map((entity: GraphDataPointType) => {
    Object.keys(entity).forEach((key) => {
      lastDefined[key as keyof GraphDataPointType] =
        entity[key as keyof GraphDataPointType] || Number(entity[key as keyof GraphDataPointType]) === 0
          ? entity[key as keyof GraphDataPointType]
          : lastDefined[key as keyof GraphDataPointType];
    });

    const finalUsageValue =
      entity.podsAvgUsage || Number(entity.podsAvgUsage) === 0
        ? entity.podsAvgUsage
        : Object.keys(entity).length > 1
        ? lastDefined.podsAvgUsage
        : undefined;

    const originalTimestamp = entity.timestamp;
    // FireFox replace
    const timestamp = dayjs(entity.timestamp?.replace(/-/g, "/")).unix();

    // @TODO should add a isFamily in the future
    const shouldSkipDeduction = LIMIT_DEDUCER_TO_REQUESTS_LINES_ONLY;

    const output = {
      originalTimestamp,
      timestamp: timestamp,
      originRequest: entity.originRequest ?? finalUsageValue ? lastDefined.originRequest : undefined,
      recommendedRequest: entity.recommendedRequest ?? finalUsageValue ? lastDefined.recommendedRequest : undefined,
      podsAvgUsage:
        entity.podsAvgUsage ?? (finalUsageValue && !shouldSkipDeduction) ? lastDefined.podsAvgUsage : undefined,
      podsMaxUsage:
        entity.podsMaxUsage ?? (finalUsageValue && !shouldSkipDeduction) ? lastDefined.podsMaxUsage : undefined,
      podsP90Usage:
        entity.podsP90Usage ?? (finalUsageValue && !shouldSkipDeduction) ? lastDefined.podsP90Usage : undefined,
      currentRequest:
        entity.currentRequest ?? (finalUsageValue && !shouldSkipDeduction) ? lastDefined.currentRequest : undefined,
      currentLimit:
        entity.currentLimit ?? (finalUsageValue && !shouldSkipDeduction) ? lastDefined.currentLimit : undefined,
      recommendedLimit:
        entity.recommendedLimit ?? (finalUsageValue && !shouldSkipDeduction) ? lastDefined.recommendedLimit : undefined,
      minAllowed: entity.minAllowed ?? (finalUsageValue && !shouldSkipDeduction) ? lastDefined.minAllowed : undefined,
      maxAllowed: entity.maxAllowed ?? (finalUsageValue && !shouldSkipDeduction) ? lastDefined.maxAllowed : undefined,
    };

    if (Number(output.podsAvgUsage) !== 0 && Number.isNaN(Number(output.podsAvgUsage)) && !shouldSkipDeduction) {
      return {
        originalTimestamp,
        timestamp: output.timestamp,
        podsAvgUsage: NaN,
        podsMaxUsage: NaN,
        podsP90Usage: NaN,
        currentRequest: NaN,
        originRequest: NaN,
        currentLimit: NaN,
        recommendedRequest: NaN,
        recommendedLimit: NaN,
        minAllowed: NaN,
        maxAllowed: NaN,
      };
    }

    return {
      originalTimestamp,
      timestamp: output.timestamp,
      podsAvgUsage: Number(output.podsAvgUsage),
      podsMaxUsage: Number(output.podsMaxUsage),
      podsP90Usage: Number(output.podsP90Usage),
      currentRequest: Number(output.currentRequest),
      originRequest: Number(output.originRequest),
      currentLimit: Number(output.currentLimit),
      recommendedRequest: Number(output.recommendedRequest),
      recommendedLimit: Number(output.recommendedLimit),
      minAllowed: Number(output.minAllowed),
      maxAllowed: Number(output.maxAllowed),
    };
  });
};
interface Props {
  title: string;
  selectedChartComponents: ChartComponents[];
  data: GraphDataType;
  marginLeft?: number;
  yTickFormatter?: (tick: string | number) => string;
  isLoading?: boolean;
  wrapperHeight?: number;
  minMaxYAxisDomainValue?: number;
  disabledZoom?: boolean;
  tooltipTrigger?: TooltipTrigger;
  frozenTooltipType?: FrozenTooltipType;
  overrideMaxY?: number;
  infoTooltip?: string;
}

const UsageChart = ({
  title,
  selectedChartComponents,
  data,
  marginLeft,
  yTickFormatter = defaultYTickFormatter,
  isLoading,
  wrapperHeight = 250,
  minMaxYAxisDomainValue = 0,
  disabledZoom,
  tooltipTrigger,
  frozenTooltipType,
  overrideMaxY,
  infoTooltip,
}: Props) => {
  const [, setDates] = useQueryParam(POLICY_TUNING_DATES_URL_PARAM, ObjectParam);
  const [selectedViewPeriod, setSelectedViewPeriod] = useViewPeriodQueryParams();

  const [selectPosition, setSelectPosition] = useState<
    { from?: number; to?: number; fromX?: string; toX?: string } | undefined
  >(undefined);

  const graphData = getDeducedData(data) ?? [];

  const minXValue = graphData[0]?.timestamp;
  const maxXValue = graphData[graphData.length - 1]?.timestamp;

  const downSampledGraphData = HAS_DOWN_SAMPLED_DATA ? getDownSampledData(graphData) : undefined;

  const chartData =
    downSampledGraphData && downSampledGraphData.length > DOWN_SAMPLED_DATA_LIMIT - 2
      ? downSampledGraphData
      : graphData;

  const maxGraphValue = Math.max(
    ...graphData.map((dataPoint) => {
      return Math.max(
        Number(dataPoint.podsAvgUsage) || 0,
        Number(dataPoint.podsMaxUsage) || 0,
        Number(dataPoint.podsP90Usage) || 0,
        Number(dataPoint.currentRequest) || 0,
        Number(dataPoint.originRequest) || 0,
        Number(dataPoint.currentLimit) || 0,
        Number(dataPoint.recommendedRequest) || 0,
        Number(dataPoint.recommendedLimit || 0),
        Number(dataPoint.minAllowed || 0),
        Number(dataPoint.maxAllowed || 0)
      );
    })
  );

  const lastXPoint = chartData[chartData.length - 1]?.timestamp;
  const firstXPoint = chartData[0]?.timestamp;

  const setDateRage = () => {
    if (selectPosition?.from && selectPosition?.to && !disabledZoom) {
      const from = Math.min(selectPosition?.from || 0, selectPosition?.to || firstXPoint || 0);
      const to = Math.max(selectPosition?.from || 0, selectPosition?.to || lastXPoint || 0);
      setDates({ from: String(from), to: String(to) });
      if (from && to && setSelectedViewPeriod) {
        setSelectedViewPeriod(String(Math.round((to - from) / 60 / 60)));
      }
    }
    setSelectPosition(undefined);
  };

  useEffect(() => {
    const handleMouseUp = () => {
      if (selectPosition?.from && selectPosition?.to && !disabledZoom) {
        setDateRage();
      }
    };

    window.addEventListener("mouseup", handleMouseUp);

    return () => {
      window.removeEventListener("mouseup", handleMouseUp);
    };
  }, [selectPosition, disabledZoom]);

  return (
    <>
      <div
        className="w-full py-[10px] unselectable-svg-text"
        style={{
          height: wrapperHeight,
        }}
      >
        {isLoading && (
          <GraphLoading className="w-full flex justify-end items-center pr-2  h-[50px] -mb-[50px] z-50 sticky" />
        )}
        <Typography variant="body2" fontWeight={600} className="flex pl-[5px]  relative">
          <div className="w-full text-center">{title}</div>
          {infoTooltip && (
            <SnapshotWrapper noDesign>
              <SnapshotChip text="Limit not shown" tooltip={infoTooltip} className="absolute right-0 top-0" />
            </SnapshotWrapper>
          )}
        </Typography>
        <ResponsiveContainer>
          <ComposedChart
            data={chartData}
            margin={{
              top: 15,
              bottom: 10,
              left: marginLeft ?? 0,
            }}
            syncId="policyTuning"
            onMouseDown={(e) => {
              e.activeLabel &&
                !disabledZoom &&
                setSelectPosition({
                  ...selectPosition,
                  from: Number(e.activeLabel),
                  fromX: e.activeLabel,
                });
            }}
            onMouseMove={(e) => {
              if (selectPosition?.from && !disabledZoom) {
                setSelectPosition({
                  ...selectPosition,
                  to: Number(e.activeLabel),
                  toX: e.activeLabel ?? String(firstXPoint),
                });
              }
            }}
            onMouseUp={() => {
              setDateRage();
            }}
            onMouseLeave={() => {
              if (
                selectPosition?.from &&
                selectPosition?.to &&
                selectPosition?.fromX &&
                selectPosition?.toX &&
                !disabledZoom
              ) {
                let to = 0;
                switch (true) {
                  case Number(selectPosition?.toX) > Number(selectPosition?.fromX):
                    to = lastXPoint;
                    break;
                  case Number(selectPosition?.toX) < Number(selectPosition?.fromX):
                    to = firstXPoint;
                    break;
                  default:
                    to = lastXPoint;
                }

                setSelectPosition({
                  ...selectPosition,
                  to: to,
                  toX: String(to),
                });
              }
            }}
          >
            <defs>
              <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                <stop offset="100%" stopColor={AverageUsageColor} stopOpacity={0.5} />
                <stop offset="100%" stopColor={AverageUsageColor} stopOpacity={0.1} />
              </linearGradient>
            </defs>
            <CartesianGrid stroke="#f5f5f5" />
            {selectedChartComponents.includes(ChartComponents.OriginRequest) && (
              <Line
                type="monotone"
                dataKey="originRequest"
                stroke={Styles.originRequest.stroke}
                dot={false}
                strokeWidth={REGULAR_STROKE_WIDTH}
                strokeDasharray={Styles.originRequest.strokeDasharray}
              />
            )}
            {selectedChartComponents.includes(ChartComponents.CurrentLimit) && (
              <Line
                type="monotone"
                dataKey="currentLimit"
                stroke={Styles.currentLimit.stroke}
                dot={false}
                strokeWidth={REGULAR_STROKE_WIDTH}
                strokeDasharray={Styles.currentLimit.strokeDasharray}
              />
            )}
            {/* {selectedChartComponents.includes(ChartComponents.RecommendedLimit) && (
              <Line
                type="monotone"
                dataKey="recommendedLimit"
                stroke={Styles.recommendedLimit.stroke}
                dot={false}
                strokeWidth={REGULAR_STROKE_WIDTH}
                strokeDasharray={Styles.recommendedLimit.strokeDasharray}
              />
            )} */}
            {selectedChartComponents.includes(ChartComponents.MaxUsage) && (
              <Area type="linear" dataKey="podsMaxUsage" stroke={MaxUsageColor} strokeWidth={REGULAR_STROKE_WIDTH} />
            )}
            {selectedChartComponents.includes(ChartComponents.PercentileUsage) && (
              <Area
                type="linear"
                dataKey="podsP90Usage"
                stroke={PercentileUsageColor}
                strokeWidth={REGULAR_STROKE_WIDTH}
              />
            )}
            {selectedChartComponents.includes(ChartComponents.AverageUsage) && (
              <Area
                type="linear"
                dataKey="podsAvgUsage"
                fill="url(#colorUv)"
                stroke={AverageUsageColor}
                strokeWidth={AVERAGE_USAGE_STROKE_WIDTH}
              />
            )}
            {selectedChartComponents.includes(ChartComponents.RecommendedRequest) && (
              <Line
                type="linear" // 'basis' | 'basisClosed' | 'basisOpen' | 'linear' | 'linearClosed' | 'natural' | 'monotoneX' | 'monotoneY' | 'monotone' | 'step' | 'stepBefore' | 'stepAfter' | Function
                dataKey="recommendedRequest"
                stroke={Styles.recommendedRequest.stroke}
                dot={false}
                strokeWidth={BOLD_STROKE_WIDTH}
              />
            )}
            {selectedChartComponents.includes(ChartComponents.CurrentRequest) && (
              <Line
                type="monotone"
                dataKey="currentRequest"
                stroke={Styles.currentRequest.stroke}
                dot={false}
                strokeWidth={BOLD_STROKE_WIDTH}
                strokeDasharray={Styles.currentRequest.strokeDasharray}
              />
            )}
            {selectPosition?.fromX && selectPosition?.toX ? (
              <ReferenceArea
                x1={selectPosition?.fromX}
                x2={selectPosition?.toX}
                stroke="gray"
                fill="gray"
                fillOpacity={0.3}
                strokeOpacity={0.3}
              />
            ) : null}
            <XAxis
              dataKey="timestamp"
              strokeWidth={2}
              type="number"
              tick={{ fontSize: "10px" }}
              domain={[minXValue, maxXValue]}
              tickFormatter={(tick: string) => {
                if (data && data.length === 0) return tick;
                return getTimeFormatFromEpochAndPeriodInHours(Number(tick), selectedViewPeriod);
              }}
            />
            <YAxis
              style={{ fontSize: "x-small" }}
              domain={
                maxGraphValue > 0
                  ? [
                      0,
                      (dataMax: number) => {
                        const maxValue = Number(
                          Number(overrideMaxY ? Math.max(overrideMaxY, dataMax) : dataMax * 1.3).toFixed(10)
                        );
                        return Math.max(maxValue, minMaxYAxisDomainValue);
                      },
                    ]
                  : undefined
              }
              tickFormatter={yTickFormatter}
              strokeWidth={2}
            />
            <Tooltip
              content={
                <CustomTooltip
                  selectedChartComponents={selectedChartComponents}
                  yTickFormatter={yTickFormatter}
                  tooltipTrigger={tooltipTrigger}
                  frozenTooltipType={frozenTooltipType}
                />
              }
              trigger={tooltipTrigger}
              wrapperStyle={NO_OUTLINE}
            />
          </ComposedChart>
        </ResponsiveContainer>
      </div>
    </>
  );
};

export type { GraphDataType };
export default UsageChart;

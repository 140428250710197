import FormSingleSelect from "../../../../components/formComponents/FormSingleSelect";
import FormTitleAndDescription from "../../../../components/formComponents/FormTitleAndDescription";
import { HPA_SECTION_CLASS_NAME } from "../../../Policies/EditFormSections/utils";
import { minReplicaWindowOptions } from "./utils";

const DISABLED = true;
interface Props {
  isCustomizedPolicy?: boolean;
}

const PredictableHistoryWindow = ({ isCustomizedPolicy }: Props) => {
  return (
    <div className={HPA_SECTION_CLASS_NAME}>
      <FormTitleAndDescription
        title="History window"
        description="Set the history window to identify if the workload is predictable."
        info={
          <>
            Workloads can be predictable <b>prior</b> to the defined history window
          </>
        }
      />
      <div className="flex flex-wrap gap-7">
        <FormSingleSelect
          label="History window"
          name="predictionHistoryWindow"
          options={minReplicaWindowOptions}
          areOptionsDisabled={!isCustomizedPolicy}
          defaultValue={"336h"}
          disabled={DISABLED}
        />
      </div>
    </div>
  );
};

export default PredictableHistoryWindow;

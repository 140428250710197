import { ListItemText, MenuItem, Typography } from "@mui/material";
import clsx from "clsx";
import { FieldHookConfig, useField } from "formik";
import { useEffect, useState } from "react";
import SingleSelect from "../SingleSelect";
import ErrorLabel from "./ErrorLabel";

const get24HoursArr = (arrayStartTime: string) => {
  const startTime = arrayStartTime.split(":");
  const startHour = parseInt(startTime[0]);
  const hoursArr = [];
  for (let i = 0; i < 24; i++) {
    const hour = (startHour + i) % 24;
    hoursArr.push(`${hour.toString().padStart(2, "0")}:00`);
  }
  return hoursArr;
};

type Props = {
  label?: React.ReactNode;
  className?: string;
  disabledOptions?: string[];
  areOptionsDisabled?: boolean;
  fullWidth?: boolean;
  additionalOnChange?: (value: string | number) => void;
  hourArrayStartTime?: string;
  showNetDayIndicator?: boolean;
  formatDisplayValue?: (value: string) => string;
} & FieldHookConfig<string | number> &
  React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>;

const FormHourSelect = ({
  label,
  className,
  disabledOptions,
  areOptionsDisabled,
  fullWidth,
  additionalOnChange,
  hourArrayStartTime = "00:00",
  showNetDayIndicator,
  formatDisplayValue = (value) => value,
  ...props
}: Props) => {
  const [options, setOptions] = useState<string[]>([]);
  const [field, meta] = useField(props);

  useEffect(() => {
    setOptions(get24HoursArr(hourArrayStartTime));
  }, [hourArrayStartTime]);

  return (
    <>
      <div className={"flex flex-col gap-1 " + (fullWidth ? "w-full" : "w-fit") + "  relative"}>
        {label && (
          <Typography variant="body2" className="text-text-lightBlack flex items-center gap-1">
            {label}
          </Typography>
        )}
        <SingleSelect<string | number>
          selected={field.value}
          className={clsx(className, "h-[34px] w-[90px]")}
          setSelected={(value) => {
            field.onChange({
              target: {
                name: field.name,
                value,
              },
            });
            if (additionalOnChange) additionalOnChange(value);
          }}
          renderOptionsFunc={() => {
            let isNextDay = false;

            return options.map((hour) => {
              const displayValue = formatDisplayValue(hour);

              if (showNetDayIndicator && hourArrayStartTime !== "01:00" && displayValue.split(":")[0] === "00") {
                isNextDay = true;
              }

              return (
                <MenuItem value={hour} key={hour} disabled={areOptionsDisabled || disabledOptions?.includes(hour)}>
                  <ListItemText
                    primary={
                      <div className="w-[120px] flex justify-between items-center">
                        <span>{displayValue}</span>
                        {isNextDay && (
                          <Typography variant="caption" className="bg-text-lightBlack text-white px-2 rounded-full">
                            next day
                          </Typography>
                        )}
                      </div>
                    }
                  />
                </MenuItem>
              );
            });
          }}
          renderValue={(value) => (value ? formatDisplayValue(String(value)) : "")}
          disabled={!!props.disabled}
        />
        {meta.touched && meta.error ? <ErrorLabel label={meta.error} /> : null}
      </div>
    </>
  );
};

export default FormHourSelect;

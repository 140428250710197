import dayjs from "dayjs";
import { StringParam, useQueryParam, withDefault } from "use-query-params";
import { SCALEOPS_COLORS } from "../../../colors";
import { camelCaseToSnakeSpace, capitalizeFirstLetter } from "../../../utils/formatterUtils";
import { OPTIMIZED_REQUEST } from "../../../utils/namesUtils";
import {
  CurrentMinReplicasColor,
  CurrentReplicasColor,
  MaxValueRecordedReplicasColor,
  OriginMinReplicasColor,
  OriginReplicasColor,
  PredictionBasedColor1,
  PredictionBasedColor2,
  PredictionBasedColor3,
  PredictionBasedColor4,
  PredictionBasedColor5,
  PredictionBasedColor6,
  RecommendedMinReplicasColor,
  RecommendedMinReplicasP60Color,
  RecommendedMinReplicasPercentileColor,
  RecommendedReplicasColor,
  TriggerBasedColor1,
  TriggerBasedColor2,
  TriggerBasedColor3,
  TriggerBasedColor4,
} from "./UsageChart/Styles";
import { HpaChartComponent } from "./UsageChart/UsageHpaChart";

export interface Policy {
  name: string;
  displayName: string;
}

export enum ViewPeriodOptions {
  // "1 month" = "672",
  "30 minutes" = "0.5",
  "1 hour" = "1",
  "4 hours" = "4",
  "12 hours" = "12",
  "1 day" = "24",
  "2 days" = "48",
  "4 days" = "96",
  "7 days" = "168",
  "2 weeks" = "336",
  "1 month" = "720",
  "Custom" = "0",
}

export enum ChartComponentKeys {
  AverageUsage = "AverageUsage",
  PercentileUsage = "PercentileUsage",
  MaxUsage = "MaxUsage",
  RecommendedRequest = "RecommendedRequest",
  CurrentRequest = "CurrentRequest",
  OriginRequest = "OriginRequest",
  // RecommendedLimit = "RecommendedLimit",
  CurrentLimit = "CurrentLimit",
}

export enum ChartComponents {
  AverageUsage = "Usage (avg)",
  PercentileUsage = "Usage (p90)",
  MaxUsage = "Usage (max)",
  RecommendedRequest = "Recommended request",
  CurrentRequest = "Current request",
  OriginRequest = "Original request",
  // RecommendedLimit = "Recommended limit",
  CurrentLimit = "Current limit",
}

export const componentStyle = {
  [ChartComponents.AverageUsage]: {
    color: "#3B8BFF",
  },
  [ChartComponents.MaxUsage]: {
    color: "#18135B",
  },
  [ChartComponents.PercentileUsage]: {
    color: "#6C70FF",
  },
  [ChartComponents.RecommendedRequest]: {
    color: "#52D39D",
  },
  [ChartComponents.CurrentRequest]: {
    color: "#EAB832",
  },
  [ChartComponents.OriginRequest]: {
    color: "#ff517a",
  },
  // [ChartComponents.RecommendedLimit]: {
  //   color: "#10AE6E",
  // },
  [ChartComponents.CurrentLimit]: {
    color: "#FFCC43",
  },
};

const triggerColors = [TriggerBasedColor1, TriggerBasedColor2, TriggerBasedColor3, TriggerBasedColor4];
export const getTriggerComponentColor = (index: number) => triggerColors[index % triggerColors.length];
const predictionColors = [PredictionBasedColor1, PredictionBasedColor2, PredictionBasedColor3, PredictionBasedColor4];
export const getPredictionComponentColor = (index: number) => predictionColors[index % predictionColors.length];
const predictionWithErrorHeadroomColors = [PredictionBasedColor5, PredictionBasedColor6];
export const getPredictionWithErrorHeadroomComponentColor = (index: number) =>
  predictionWithErrorHeadroomColors[index % predictionWithErrorHeadroomColors.length];

export const getReplicasComponentsStyle = (components: Record<string, HpaChartComponent>) =>
  Object.values(components)
    .filter(
      (item) =>
        item.startsWith("triggerBasedReplicas:") ||
        item.startsWith("predictionBasedReplicas:") ||
        item.startsWith("predictionBasedReplicasWithHeadroom:")
    )
    .reduce<{ [x: string]: { color: string } }>((acc, item, idx) => {
      acc[item] = {
        color: item.startsWith("triggerBasedReplicas:")
          ? getTriggerComponentColor(idx)
          : item.startsWith("predictionBasedReplicas:")
          ? getPredictionComponentColor(idx)
          : getPredictionWithErrorHeadroomComponentColor(idx),
      };
      return acc;
    }, hpaComponentStyle);

export const hpaComponentStyle = {
  maxTriggerValueReplicas: {
    color: SCALEOPS_COLORS.main.blue,
  },
  currentReplicas: {
    color: CurrentReplicasColor,
  },
  currentMinReplicas: {
    color: CurrentMinReplicasColor,
  },
  currentMaxReplicas: {
    color: PredictionBasedColor1,
  },
  recommendedReplicas: {
    color: RecommendedReplicasColor,
  },
  recommendedMinReplicas: {
    color: RecommendedMinReplicasColor,
  },
  recommendedMinReplicasP60: {
    color: RecommendedMinReplicasP60Color,
  },
  recommendedMinReplicasPercentile: {
    color: RecommendedMinReplicasPercentileColor,
  },
  maxValueRecordedReplicas: {
    color: MaxValueRecordedReplicasColor,
  },
  originalReplicas: {
    color: OriginReplicasColor,
  },
  originalMinReplicas: {
    color: OriginMinReplicasColor,
  },
};

const addTimeSuffixIfMissing = (value: string) => {
  let output = value;
  if (typeof value === "string" && !value.match(/[smhdwMy]/)) {
    output = `${value}h`;
  }
  return output;
};

export default addTimeSuffixIfMissing;

export const getEndDate = () => dayjs.utc().format("YYYY-MM-DDTHH:mm:00");

export enum PolicyTuningTabs {
  PodRightsizing = "Pod Rightsizing",
  Hpa = "HPA",
  Analytics = "Troubleshoot",
  Gpu = "GPU",
  Network = "Network",
  Events = "Events",
  Yaml = "Yaml",
}

export const POLICY_TUNING_DATES_URL_PARAM = "policyTuningDates";
export const POLICY_TUNING_VIEW_PERIOD_URL_PARAM = "policyTuningViewPeriod";

export const METRIC_BOX_CLASS_NAME =
  "flex items-center justify-center h-[30px] px-4 border rounded border-border text-[12px] w-auto whitespace-nowrap";
export const LABEL_BOX_CLASS_WRAPPER = "mt-2 flex items-center justify-center gap-1";

export const displayNameFormatter = (key: string) => {
  if (key.trim().toLocaleLowerCase() === ChartComponents.RecommendedRequest.toLocaleLowerCase()) {
    return OPTIMIZED_REQUEST;
  }
  return key;
};

export const displayNameHpaLegendFormatter = (key: string) => {
  if (key.includes("Trigger based replicas:cpu") || key.toLocaleLowerCase() === "max trigger value replicas")
    return "Replicas by triggers";
  return capitalizeFirstLetter(key.trim().toLocaleLowerCase().replace(/:/g, ": "));
};

export const displayNameHpaTooltipFormatter = (key: string) => {
  if (key.toLocaleLowerCase() === "maxtriggervaluereplicas") return "Replicas by triggers";
  return capitalizeFirstLetter(camelCaseToSnakeSpace(key.trim().replace(/:/g, " ")));
};

export const useViewPeriodQueryParams = () => {
  return useQueryParam(POLICY_TUNING_VIEW_PERIOD_URL_PARAM, withDefault(StringParam, ViewPeriodOptions["1 day"]));
};

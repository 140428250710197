export const KeyValTooltipOption = ({
  name,
  value,
  background,
  suffix,
}: {
  name: string;
  value: number;
  background: string;
  suffix?: string;
}) => {
  return (
    <p className="flex gap-2 items-center">
      <span
        style={{
          background: background,
        }}
        className="w-6 h-2 rounded"
      />
      {`${name}: ${value} ${suffix || ""}`}
    </p>
  );
};

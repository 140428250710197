import InfoTooltip from "../components/Tooltip";
import SnapshotIcon from "../Icons/SnapshotIcon";
import clsx from "clsx";

interface Props {
  text: string;
  tooltip?: string;
  className?: string;
  theme?: CHIP_THEME;
}

export enum CHIP_THEME {
  "LIGHT",
  "DARK",
}

const SnapshotChip = ({ text, tooltip, className, theme = CHIP_THEME.LIGHT }: Props) => {
  const themeClass =
    theme === CHIP_THEME.LIGHT
      ? "text-primary-purpleBlue border border-primary-purpleBlue"
      : "bg-primary-purpleBlue text-white";
  return (
    <InfoTooltip title={tooltip} disabled={!tooltip} maxWidth={500}>
      <div
        className={clsx(
          "flex gap-1 px-[6px] py-[2px] rounded-xl items-center justify-center text-[10px] whitespace-nowrap",
          themeClass,
          className
        )}
        style={{ textShadow: "0px 4px 4px rgba(0, 0, 0, 0.15)" }}
      >
        <SnapshotIcon height={12} width={12} />
        {text}
      </div>
    </InfoTooltip>
  );
};

export default SnapshotChip;

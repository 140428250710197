import { useEffect, useState } from "react";
import { ArrayParam, useQueryParam } from "use-query-params";
import { MAIN_YELLOW } from "../../../colors";
import WarningIcon from "../../../Icons/WarningIcon";
import { PodReasons } from "../../autoscalers/Utils";
import CustomFilterChip from "../../CustomFilterChip";

const QUERY_PARAM_KEY = "scaleDownReasonsWarn";

export interface BlockedNodesFilterProps {
  label: string;
  paramsKeys: PodReasons[];
  disabled?: boolean;
}

const BlockedNodesFilter = ({ label, paramsKeys, disabled }: BlockedNodesFilterProps) => {
  const [queryParams, setQueryParams] = useQueryParam(QUERY_PARAM_KEY, ArrayParam);
  const [isSelected, setIsSelected] = useState(false);

  useEffect(() => {
    setIsSelected(!!queryParams && paramsKeys.every((key) => queryParams.includes(key)));
  }, [queryParams]);

  const onClick = () => {
    if (isSelected && queryParams) {
      setQueryParams(queryParams.filter((key) => !paramsKeys.includes(key as PodReasons)));
    } else {
      const keys = [...(queryParams || []), ...paramsKeys];
      const uniqueKeys = Array.from(new Set(keys));
      setQueryParams(uniqueKeys);
    }
  };

  return (
    <CustomFilterChip
      label={
        <div className="flex items-center justify-center gap-1">
          <WarningIcon fill={MAIN_YELLOW} width={16} height={16} />
          {label}
        </div>
      }
      onClick={onClick}
      selected={isSelected}
      disabled={disabled}
    />
  );
};

export default BlockedNodesFilter;

import { DialogTitle, Typography } from "@mui/material";
import CustomIcon from "../../Icons/CustomIcon";
import LockIcon from "../../Icons/LockIcon";
import { components } from "../../api/schema";
import { isBuiltInPolicy } from "../../utils/policyUtils";
import useIsReadyOnlyFrontEnd from "../../utils/useIsReadyOnlyFrontEnd";
import { TOP_CHIP_CLASS_NAME } from "./EditPolicyDrawer";

interface Props {
  policy: components["schemas"]["V1alpha1Policy"] | undefined;
  children?: React.ReactNode;
}

const PolicyTitle = ({ policy, children }: Props) => {
  const isReadyOnlyFrontEnd = useIsReadyOnlyFrontEnd();
  const isCustomizedPolicy = !isBuiltInPolicy(policy) && !isReadyOnlyFrontEnd;

  return (
    <DialogTitle className="bg-text-lightBlack text-white min-h-[56px] flex">
      <Typography variant="body2" className="flex items-center gap-1 grow">
        {!isCustomizedPolicy && <LockIcon />}
        <div className="flex items-center">{policy?.metadata?.name}</div>
        {isBuiltInPolicy(policy) && (
          <Typography variant="caption" className={TOP_CHIP_CLASS_NAME}>
            <CustomIcon width={14} height={14} />
            Built in policy
          </Typography>
        )}
      </Typography>
      {children}
    </DialogTitle>
  );
};

export default PolicyTitle;

import { HPAPolicy } from "../../../../api/fetcher";

export const minReplicaPercentileOptions: [string, string][] = [
  ["60%", "60"],
  ["70%", "70"],
  ["80%", "80"],
  ["90%", "90"],
  ["100% (MAX)", "100"],
];

export const minReplicaWindowOptions: [string, string][] = [
  ["7 days", "168h"],
  ["14 days", "336h"],
  ["21 days", "504h"],
];

export enum MinReplicasStrategy {
  SetHistoryBasedForAllWorkloads = "Set history based for all workloads",
  SetHistoryBasedForPredictableWorkloads = "Set history based for predictable workloads",
  SetHistoryBasedForStaticWorkloads = "Set history base for static workloads",
  KeepMinReplicasForAllWorkloads = "Keep min replicas for all workloads",
  SetMinReplicasForAllWorkloads = "Set min replicas for all workloads",
}

export const getMinReplicasStrategy = (selectedPolicyToEdit: HPAPolicy | undefined): string => {
  let minReplicasStrategy: string = MinReplicasStrategy.KeepMinReplicasForAllWorkloads;

  switch (true) {
    case selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas?.setMinReplicas === undefined &&
      selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas?.predictableWorkloads?.keepMinReplicas === true &&
      selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas?.generalWorkloads?.keepMinReplicas === true:
      minReplicasStrategy = MinReplicasStrategy.KeepMinReplicasForAllWorkloads;
      break;
    case Number(selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas?.setMinReplicas) >= 0 &&
      !selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas?.predictableWorkloads?.keepMinReplicas &&
      !selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas?.generalWorkloads?.keepMinReplicas:
      minReplicasStrategy = MinReplicasStrategy.SetMinReplicasForAllWorkloads;
      break;
    case selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas?.setMinReplicas === undefined &&
      !selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas?.predictableWorkloads?.keepMinReplicas &&
      !selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas?.generalWorkloads?.keepMinReplicas:
      minReplicasStrategy = MinReplicasStrategy.SetHistoryBasedForAllWorkloads;
      break;
    case selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas?.setMinReplicas === undefined &&
      !selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas?.predictableWorkloads?.keepMinReplicas &&
      selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas?.generalWorkloads?.keepMinReplicas === true:
      minReplicasStrategy = MinReplicasStrategy.SetHistoryBasedForPredictableWorkloads;
      break;
    case selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas?.setMinReplicas === undefined &&
      selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas?.predictableWorkloads?.keepMinReplicas === true &&
      !selectedPolicyToEdit?.spec?.policyOptimize?.minReplicas?.generalWorkloads?.keepMinReplicas:
      minReplicasStrategy = MinReplicasStrategy.SetHistoryBasedForStaticWorkloads;
      break;
  }

  return minReplicasStrategy;
};

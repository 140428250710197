import { useEffect, useState } from "react";
import { components } from "../../../api/schema";
import useIsReadyOnlyFrontEnd from "../../../utils/useIsReadyOnlyFrontEnd";

interface Props {
  params: components["schemas"]["UtilsWorkload"];
}

const useIsAutomationButtonDisabled = ({ params }: Props): boolean => {
  const isReadyOnlyFrontEnd = useIsReadyOnlyFrontEnd();
  const [isAutomationButtonDisabled, setIsAutomationButtonDisabled] = useState<boolean>(false);

  useEffect(() => {
    if (
      isReadyOnlyFrontEnd ||
      !params?.isEditable ||
      params?.isAutomationExcluded ||
      params?.isScaleOpsWorkload ||
      params?.isAutoForced
    ) {
      setIsAutomationButtonDisabled(true);
    } else {
      setIsAutomationButtonDisabled(false);
    }
  }, [params, isReadyOnlyFrontEnd]);

  return isAutomationButtonDisabled;
};

export default useIsAutomationButtonDisabled;

import { SCALEOPS_COLORS } from "../../../../colors";
import CircleUpArrowIcon from "../../../../Icons/CircleUpArrowIcon";
import DisableIcon from "../../../../Icons/DisableIcon";

export enum Event {
  podOptimizedCount = "podOptimizedCount",
  podEvictionCount = "podEvictionCount",
  optimizationBlockedCount = "optimizationBlockedCount",
}

export const EventsDisplayName: Record<Event, string> = {
  [Event.podOptimizedCount]: "Pod optimized",
  [Event.podEvictionCount]: "Pod eviction",
  [Event.optimizationBlockedCount]: "Blocked optimization",
};

export const GraphElements: Record<
  Event,
  { color: string; icon: JSX.Element; iconClassName?: string; isBlockedLineStyle?: boolean }
> = {
  [Event.podOptimizedCount]: {
    color: SCALEOPS_COLORS.primary.pink,
    icon: <CircleUpArrowIcon />,
  },
  [Event.podEvictionCount]: {
    color: SCALEOPS_COLORS.primary.purpleBlue,
    iconClassName: "transform rotate-45",
    icon: <CircleUpArrowIcon />,
  },
  [Event.optimizationBlockedCount]: {
    color: SCALEOPS_COLORS.events.blockedOptimization,
    icon: <DisableIcon />,
    isBlockedLineStyle: true,
  },
};

export type EventChartPoint = {
  timestamp: number;
  podOptimizedCount?: number;
  podEvictionCount?: number;
  optimizationBlockedCount?: number;
  data?: {
    podOptimizedCount?: {
      cpuBefore?: number;
      cpuAfter?: number;
      memoryBefore?: number;
      memoryAfter?: number;
    };
    optimizationBlocked?: {
      message?: string;
    };
    podEvictionCount?: number;
  };
};

export const exampleData: EventChartPoint[] = [
  {
    timestamp: 1733344113060,
  },
  {
    timestamp: 1733344245960,
    optimizationBlockedCount: 250,
    podEvictionCount: 3,
    podOptimizedCount: 123,
    data: {
      podOptimizedCount: {
        cpuBefore: 20,
        cpuAfter: 2,
        memoryBefore: 10000000000000,
        memoryAfter: 10000000,
      },
      optimizationBlocked: {
        message: "example of blocked optimization message",
      },
    },
  },
  {
    timestamp: 1733344378860,
    optimizationBlockedCount: 11250,
    podEvictionCount: 1,
    podOptimizedCount: 123,
    data: {
      podOptimizedCount: {
        cpuBefore: 20,
        cpuAfter: 2,
        memoryBefore: 10000000000000,
        memoryAfter: 10000000,
      },
      optimizationBlocked: {
        message: "example of blocked optimization message",
      },
    },
  },
  {
    timestamp: 1733344511760,
    optimizationBlockedCount: 250,
    podOptimizedCount: 12,
    data: {
      podOptimizedCount: {
        cpuBefore: 20,
        cpuAfter: 2,
        memoryBefore: 10000000000000,
        memoryAfter: 10000000,
      },
      optimizationBlocked: {
        message: "example of blocked optimization message",
      },
    },
  },
  {
    timestamp: 1733344644660,
  },
  {
    timestamp: 1733344777560,
    optimizationBlockedCount: 250,
    podEvictionCount: 750,
    data: {
      podOptimizedCount: {
        cpuBefore: 20,
        cpuAfter: 2,
        memoryBefore: 10000000000000,
        memoryAfter: 10000000,
      },
      optimizationBlocked: {
        message: "example of blocked optimization message",
      },
    },
  },
  {
    timestamp: 1733344910460,
    optimizationBlockedCount: 250,
    podEvictionCount: 11,
    podOptimizedCount: 123,
    data: {
      podOptimizedCount: {
        cpuBefore: 20,
        cpuAfter: 2,
        memoryBefore: 10000000000000,
        memoryAfter: 10000000,
      },
      optimizationBlocked: {
        message: "example of blocked optimization message",
      },
    },
  },
  {
    timestamp: 1733345043360,
  },
  {
    timestamp: 1733345176260,
  },
];
